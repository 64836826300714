import React, { useState } from 'react'
import { ResponseContent, ResponseText, ResponseTitle } from './styled'

export default function Response({ response, challenge }) {
  const renderBoldText = (text) => {
    // Split the text by '**' and map through each part
    const parts = text.split(/(\*\*[^*]+\*\*)/g);
    
    return parts.map((part, index) => {
      // If the part matches the pattern '**text**', remove '**' and wrap with <strong>
      if (part.startsWith("**") && part.endsWith("**")) {
        return <strong key={index}>{part.slice(2, -2)}</strong>;
      }
      // Otherwise, return the part as normal text
      return <span key={index}>{part}</span>;
    });
  };

  return (
    <>
      <ResponseContent >
        {response ?
          <>
            <ResponseTitle>
              Resposta do desafio
            </ResponseTitle>
            <ResponseText>
              {challenge?.resposta && renderBoldText(challenge.resposta)}
            </ResponseText>
          </>
          : null
        }
      </ResponseContent>
    </>
  )
}
