import React, { useContext, useState } from 'react';
import { CardBody, CardContainer, CardHeader, CardLabel, FamousCard, FamousTitle, PlansBody } from './styled';
import Button from 'components/Form/Button';
import { Icon } from 'ui/styled';
import { useHistory } from 'react-router-dom';
import { CoreContext } from 'context/CoreContext';
import { CreateCheckout } from 'services/mobdict';
import { toast } from 'react-toastify';
import { exposeMobdictError } from 'utils';
import useI18n from 'hooks/useI18n';

export default function PlansCard({ plan, dictations, price, action, famous, planIndex, chaves_pesquisa, free, lp }) {
  const history = useHistory();
  const navigate = to => history.push(`/${to}`);
  const { setModal, user, setCheckout, language } = useContext(CoreContext)

  const { t } = useI18n(false)

  const [loading, setLoading] = useState(false)

  const handleNavigate = async () => {
    if (free) {
      const response = await fetch("https://api.geoapify.com/v1/ipinfo?&apiKey=d94fd006c5b540e69c94ebe62fb04d01")
      .then(response => response.json())

      // if (response.country.name === "Brazil") {
      //   setModal({ type: "payments" });
      // }
      setModal(null);
      navigate('register');
      return;
    }

    const payload = {
      lookup_key: chaves_pesquisa,
      id: user?.id,
      url: `${window.location.origin}/dashboard`,
      idioma: language
    }

    if (lp) {
      setModal(null);
      setCheckout(payload)
      navigate('login')
      return;
    }

    if(!loading){
      setLoading(true)

      const result = await CreateCheckout(payload)

      if( !exposeMobdictError(result, !( result?.checkout_url ) )){
          window.open(result?.checkout_url, "new")
          setModal(null);
      }

      setLoading(false)
    }
  }

  return (
    <CardContainer>
      <FamousCard>

        {
          famous && (
            <FamousTitle>
              <Icon icon={'star'} /> { t("dashboard_plan_pop") }
            </FamousTitle>
          )
        }
      </FamousCard>
      <CardHeader plan={(planIndex||0)+1}>
        {plan}
      </CardHeader>
      <PlansBody>
        <CardBody>
          <CardLabel>
            {dictations}
          </CardLabel>
        </CardBody>
        <CardBody>
          <CardLabel>
            <strong>{price}</strong>
          </CardLabel>
        </CardBody>
      </PlansBody>
      <Button secondary loading={loading} nospace onClick={handleNavigate}>
        {action}
      </Button>
    </CardContainer>
  );
}
