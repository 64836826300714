import styled from "styled-components";

export const CommonQuestionsContent = styled.div.attrs({
})`
  background-color: ${props => props.faq ? props.theme.palette.colors.white : props.theme.palette.primary.main};
  padding: ${props => props.faq ? '16px' : '64px 96px'} ;
  width: 100%;
${props => props.faq ? `
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    ` : ``};
  @media (max-width: 991px) {
    padding: 32px 24px;
  }
  `;
export const FaqsContainer = styled.div.attrs({
})`
`;

export const Question = styled.div.attrs({
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => props.faq ? props.theme.palette.colors.almostwhite : props.theme.palette.colors.question};
  border-radius: ${props => props.open ? `8px 8px 0px 0px;` : `8px`};
  padding: 16px 16px;
  margin-top: 4px;
  cursor: pointer;
`;

export const Text = styled.div.attrs({
})`
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  color: ${props => props.faq ? props.theme.palette.colors.black : props.theme.palette.colors.white};

`;

export const Answer = styled.div.attrs({
})`  
  background-color: ${props => props.faq ? props.theme.palette.colors.almostwhite : props.theme.palette.colors.question};
  padding: 0px 16px 16px;
  border-radius: 0px 0px 8px 8px;
`;
export const TextAnswer = styled.div.attrs({
})`  
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 25.6px;
  text-align: left;
  color: ${props => props.faq ? props.theme.palette.colors.black : props.theme.palette.colors.white};

`;

export const QuestionIcon = styled.img.attrs((props) => (({
  src: props.faq ? '/icons/chevron-black.svg' : '/icons/chevron.svg'
})))`
    width: 18px;
    height: 18px;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    ${props => props.open ? `transform: rotate(180deg);`
    : ``
  }
`;

export const CommonTitle = styled.div.attrs({
})`
  font-family: Poppins;
  font-size: 24px;
  font-weight: 700;
  line-height: 48px;
  text-align: left;
  color: ${props => props.faq ? props.theme.palette.primary.main : props.theme.palette.colors.white};
  margin-bottom: 32px;
  display: flex;
  gap: 8px;
  align-items: flex-start;
`;