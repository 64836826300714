import styled from "styled-components";

export const WritingContainer = styled.div.attrs({
})`
    display: flex;
    align-items: flex-start;
    width: 100%;
    flex-direction: column;
    margin-top: 24px;
`;

export const WritingButtonContainer = styled.div.attrs({
})`
    display: flex;
    width: 344px;
    gap: 4px;
    @media (max-width: 423px) {
        width: 100%;
        flex-direction: column;
    }
`;

export const WritingButton = styled.div.attrs({
})`
  width: 100%;
  height: 48px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 4px 4px 0 0;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 700;
  line-height: 48px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  background: ${props => !props.active ? props.theme.palette.colors.lightgrey : props.theme.palette.primary.main};
  color: ${props => props.theme.palette.colors.white};
`;

export const WritingContent = styled.div.attrs({
})`
    display: flex;
    background: ${props => props.theme.palette.colors.almostwhite};
    width: 100%;
    border-radius: 4px;
    flex-direction: ${props => props.row ? 'row' : 'column'};
    ${props => props.hand ? `
        padding: 16px;
        gap: 24px;
        `: ''}

    @media (max-width: 991px) {
      flex-direction: column;
      align-items: center;
    }

`;
export const WritingHandContainer = styled.div.attrs({
})`
    display: flex;
    width: 100%;
    border-radius: 4px;

`;

export const WritingImgContent = styled.div.attrs({
})`
    width: 135px;
    position: relative;
    background: ${props => props.theme.palette.colors.white};
`;
export const WritingImg = styled.img.attrs({
})`
    padding: 16px 4px;
    border-radius: 16px;
    background: ${props => props.theme.palette.colors.lightgrey};
    height: 100%;
    width: 100%;
    cursor: pointer;
`;

export const WritingButtonDelete = styled.div.attrs({
})`
    position: absolute;
    top: 38%;
    right: -16px;
    width: 32px;
    height: 32px;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 50%;
    cursor: pointer;
    background: ${props => props.theme.palette.error.main};
`;


export const SpecialCharacters = styled.div.attrs({
})`
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    width: 100%;   
    background: ${props => props.theme.palette.colors.backgroundgrey};
    padding: 8px 16px;
    border-radius: 0 4px 0px 0px;
`;

export const SpecialCharacterTitle = styled.div.attrs({
})`
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 37px;
    margin-right: 8px;
    text-align: center;
    color: ${props => props.theme.palette.colors.black};
`;

export const Character = styled.div.attrs({
})`
    width: 36px;
    height: 37px;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 12px;
    cursor: pointer;
    background: ${props => props.theme.palette.colors.character};
    font-family: Maven Pro;
    font-size: 20px;
    font-weight: 500;
    line-height: 48px;
    text-align: center;
    color: ${props => props.theme.palette.colors.black};
`;

export const WritingInputContent = styled.div.attrs({
})`
    width: 100%;
    align-items: center;
    display: flex;
    padding: 17px 16px;
    gap: 24px;

    @media (max-width: 550px) {
        flex-direction: column;
        gap: 8px;
        padding: 17px 2px;
    }
`;

export const WritingHandContent = styled.div.attrs({
})`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    width: 100%;
    padding: 16px 26px;
    background: ${props => props.theme.palette.colors.white};
`;

export const HandTitleContainer = styled.div.attrs({
})`
    display: flex;
    align-items: flex-start;
    gap: 8px;
    flex-direction: column;
    max-width: 769px;
`;

export const HandTitleText = styled.textarea.attrs({
  id: 'img-text'
})`
  border: none;
  outline: none;
  resize: none;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  background: transparent;
  padding: 0;
  margin: 0;
  line-height: inherit;
`;

export const WritingHandTitle = styled.div.attrs({
})`
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 30.4px;
    letter-spacing: 1px;
    text-align: left;
    color: ${props => props.theme.palette.colors.black};
`;

export const Image =  styled.img.attrs({
  onError: e => e.target.style.display = 'none',
  onLoad: e => e.target.style.display = 'initial'
}).attrs((props) => ({
    src: props.src,
}))`
    width: 200px;
    height: 200px;
`;