import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import {
  ImageBackground,
  PlansContent
} from './styled'
import ContainerPlans from 'containers/Plans';
import PlansLp from '../../components/PlansLp';
import { default as PlansComp } from 'components/Plans';
import { useEffect, useState } from 'react';
import { ReadObject } from 'services/storage';

export default function Plans () {
  const [logged, setLogged] = useState(false)

  const init = () => {
      const authentication = ReadObject('authentication')
      if (authentication?.access_token) {
          setLogged(true)
      }
  }
    useEffect(() => { 
      init()
      window.scrollTo(0,0) ;
    },[])

  return (
    <>
      <ContainerPlans>
        <PlansContent>
          <ImageBackground src={'/images/background.avif'} />
          {logged ? <PlansComp /> : <PlansLp />}
        </PlansContent>
      </ContainerPlans>
    </>
  )
}