import React, { useState } from 'react'
import { TraductionButton, TraductionContent, TraductionText, TraductionTitle } from './styled'
import Button from 'components/Form/Button'
import { Icon } from 'ui/styled'
import useI18n from 'hooks/useI18n'

export default function Traduction({ dict, toCorrect }) {
  const [traduction, setTraduction] = useState(false)

  const { t } = useI18n(false)

  // console.log('dict', dict, toCorrect)

  return (
    <>
      <TraductionContent traduction={traduction}>
        {
          traduction ?
            <>
              <TraductionTitle>
                { t("dashboard_dict_translate") }
              </TraductionTitle>
              <TraductionText dangerouslySetInnerHTML={ toCorrect ? { __html:toCorrect?.texto_reforco } : {__html:dict?.traducao}}>
              </TraductionText>
              <TraductionButton>
                <Icon icon='close-black' pointer onClick={() => setTraduction(false)} />
              </TraductionButton>

            </> : <Button outline secondary width='fit-content' colorBlack small onClick={() => setTraduction(true)}>
              { t("dashboard_dict_see_translate") }
            </Button>
        }
      </TraductionContent>
    </>
  )
}
