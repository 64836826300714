import styled from "styled-components";

export const PhoneContainer = styled.div.attrs({
})`
    width: 100%;    
`;

export const PhoneInputLabel = styled.label.attrs({
})`
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;

`;