import styled from 'styled-components'

export const MoreInfosContainer = styled.div.attrs({
})`          
    background: ${props => props.theme.palette.primary.main};
    display: flex;
    padding: 104px 96px;
    flex-direction: column;

    @media (max-width: 991px) {
        padding: 24px;
    }
`;

export const MoreInfos = styled.div.attrs({
})`          
    display: flex;
    flex-direction: column;
    gap: 64px;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 72px;
    @media (max-width: 991px) {
        padding: 24px;
    }
`;

export const MoreInfosTitle = styled.div.attrs({
})`          
    color: ${props => props.theme.palette.white.main};
    font-family: Poppins;
    font-size: 22px;
    font-weight: 800;
    line-height: 48px;
    text-align: left;
`;

export const MoreInfosTitleContent = styled.div.attrs({
})`          
    display: flex;
    gap: 8px;
    flex-wrap: wrap;

`;

export const MoreInfosText = styled.div.attrs({
})`          
    color: ${props => props.theme.palette.white.main};
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 34px;
    text-align: left;
    margin-top: 16px;
    margin-bottom: 40px;
`; 