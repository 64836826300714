import styled from "styled-components";

export const ContactUsContent = styled.div.attrs({
})` 
    display: flex;
    width: 100%;
    max-width: 534px;
    flex-direction: column;
`;

export const ContactContainer = styled.div.attrs({
})`
    display: flex;
    width: 100%;
    padding: 6px;
    @media (max-width: 768px) {
        flex-direction: column;
        gap: 4px;
    }
`;

export const ContactUsFormTitle = styled.div.attrs({
})`
    display: flex;
    align-items: center;
    min-height: 56px;
    height: 100%;
    border-radius: 8px 0px 0px 8px;
    padding: 8px;
    background-color: ${props => props.theme.palette.colors.backgroundgrey};
    width: 164px;
    @media (max-width: 768px) {
        width: 100%;
        border-radius: 8px 8px 0px 0px;
    }
`;
export const ContactUsFormValues = styled.div.attrs({
})`
    display: flex;
    align-items: center;
    min-height: 56px;
    height: 100%;
    border-radius: 0px 8px 8px 0px;
    padding: 8px;
    background-color: ${props => props.theme.palette.colors.almostwhite};
    width: 360px;
    gap: 8px;
    @media (max-width: 768px) {
        width: 100%;
        border-radius: 0px 0px 8px 8px;
    }
`;