import React, { useContext, useMemo } from 'react'
import { RankingContainer } from './styled'
import { ButtonContainer, Icon, Title } from 'ui/styled'
import BasicTable from 'components/Form/Table';
import Button from 'components/Form/Button';
import { numerize } from 'utils';
import { CoreContext } from 'context/CoreContext';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import useI18n from 'hooks/useI18n';

export default function Ranking({ isRanking }) {

  const history = useHistory();
  const navigate = to => history.push(`/${to}`);

  const { ranking, user } = useContext(CoreContext)

  const { t } = useI18n(false)

  const rankingsData = useMemo(() => {
    const next_rankings = [
      ...(ranking?.ranking||[])?.map(m => ({ 
        position: numerize(m?.rank), 
        name: m?.username, 
        points: numerize(m?.score), 
        itisme: (user?.username === m?.username) 
      }))
    ]
    if(!!isRanking){ return next_rankings ;}
    return next_rankings?.slice(0, 10);
  }, [ranking, isRanking]);

  const columns = [
    { title: `${ t("dashboard_home_ranking_position") }`, ref: 'position' },
    { title: `${ t("dashboard_home_ranking_name") }`, ref: 'name' },
    { title: `${ t("dashboard_home_ranking_points") }`, ref: 'points', position: 'center' },
  ]

  return (
    <>
      <RankingContainer isRanking={isRanking}>
        <Title hasIcon={isRanking}>
          { !isRanking ? null : <Icon icon='logo-mobdict' width={44} nomargin /> }
          { t("dashboard_home_ranking_title") }
        </Title>
        <BasicTable columns={columns} rows={rankingsData} />
        {
          isRanking ? null :
          <ButtonContainer center>
            <Button outline secondary colorBlack width='129px' onClick={() => navigate('ranking')}>
              { t("dashboard_home_ranking_see_more") }
            </Button>
          </ButtonContainer>
        }
      </RankingContainer>
    </>
  )
}
