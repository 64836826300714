import styled from "styled-components";

export const SayingErrorContainer = styled.div.attrs({
})`
  display: flex;
  gap: 40px;
  justify-content: center;
  margin-top: 90px;
  align-items: center;
  flex-wrap: wrap;
`;

export const SayingErrorImg = styled.img.attrs({
  src: '/icons/sayingerror.svg'
})`
  max-width: 283.59px;
  max-height: 350px;
`;

export const SayingErrorContent = styled.div.attrs({
})`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 768px;
  width: 100%;
`;

export const SayingErrorText = styled.div.attrs({
})`
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
  text-align: left;
  color: ${props => props.theme.palette.colors.black};

`;

export const SayingErrorTableContainer = styled.div.attrs({
})`
  display: flex;
  gap: 48px;
  width: 100%;
  margin-top: 20px;
  flex-wrap: wrap;
  justify-content: ${props => props.reinforcement ? 'flex-start' : 'center'};
`;

export const SayingButtonContainer = styled.div.attrs({
})`
  display: flex;
  gap: 15px;
  margin-top: 20px;
  flex-wrap: wrap;
  justify-content: center;
`;