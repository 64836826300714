import styled from "styled-components";

export const AbountContent = styled.div.attrs({
})` 
    display: flex;
    flex: 1;
    flex-direction: column;
`;

export const AboutContainer = styled.div.attrs({
})`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    min-width: 214px;
`;

export const AboutText = styled.div.attrs({
})`
`;