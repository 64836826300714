import styled from 'styled-components'

export const FormText = styled.div.attrs({
})`           
    font-size: 15px; 
    color: ${props => props.theme.palette.colors.grey};
    margin-bottom: 30px;
    margin-left: 50px;
`;

export const FormSpacer = styled.div.attrs({
})`           
    margin: 32px 0;
    border-bottom: 1px solid ${props => props.theme.palette.colors.backgroundgrey};

`;

export const ForgotContent = styled.div.attrs({
})`
    background: ${props => props.theme.palette.colors.white};
    max-width: 700px;
    padding: 32px 40px;
    border-radius: 8px;
    flex: 1;
    @media(max-width: 767px){
         max-height: 100%;
    }
`;

export const ForgotContainer = styled.div.attrs({
})`
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0 20px;
    margin-top: 60px;
`;

