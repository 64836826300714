import SayingError from 'components/SayingError'
import Success from 'components/Success'
import React from 'react'

export default function SuccessAndError({ toCorrect, error, action, restart, loading }) {
  return (
    <>
      {
        error ?
          <SayingError toCorrect={toCorrect} action={action} error={error} />
            :
          <Success restart={restart} loading={loading} />
      }
    </>
  )
}
