import styled from "styled-components";

export const TraductionContent = styled.div.attrs({
})`
  min-height: 95px;
  width: 100%;
  background: ${props => !props.traduction ? 'transparent' : props.theme.palette.colors.backgroundgrey};
  border-radius: 8px;
  padding: 16px 26px;
  display: flex;
  justify-content: ${props => !props.traduction ? 'center' : 'space-between'};
  align-items: center;
  gap: 20px;
  margin-top: 75px;
  transition: background 0.5s ease;
  @media (max-width: 668px) {
    flex-direction: column;
  }
`;


export const TraductionTitle = styled.div.attrs({
})`
  font-family: Poppins;
  font-size: 16px;
  font-weight: 700;
  line-height: 48px;
  color: ${props => props.theme.palette.primary.main};

`;
export const TraductionText = styled.div.attrs({
})`
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 1px;
  text-align: left;
  color: ${props => props.theme.palette.colors.black};
`;

export const TraductionButton = styled.div.attrs({
})`
  
`;