import styled from 'styled-components'


export const DashboardMenuContainer = styled.div.attrs({
})`           
    position: fixed;
    top:0;
    bottom: 0;
    left: 0;
    z-index: 100;
`;

export const DashboardMenu = styled.div.attrs({
    className: 'menu-contant'
})`           
    width: 252px;
    background: ${props => props.theme.palette.colors.white};
    min-height: 100vh;
    max-height: 100vh;
    padding: 28px 20px 0 20px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    position: relative;
    border-right: 1px solid ${props => props.theme.palette.colors.backgroundgrey};
`;

export const DashboardMenuHeader = styled.div.attrs({
})`           
    height: 60px;
    width: 100%; 
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 15px;
    color: ${props => props.theme.palette.colors.white};
    `;

export const DashboardMenuHeaderIcon = styled.img.attrs({
})`           
    cursor: pointer;
    display: none;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 32px;
    height: 32px;
    @media (max-width: 991px) {
        display: block;
    }
`;

export const DashboardMenuHeaderUserContent = styled.div.attrs({
})`           
`;

export const DashboardMenuHeaderUserImage = styled.div.attrs({
})`           
    width: 160px;
    height: 160px; 
    border-radius: 80px; 
    background: ${props => props.theme.palette.colors.grey} url(/logo1024.png) no-repeat center center / cover;
    margin: 0 auto 12px;
    overflow: hidden;
`;

export const DashboardMenuHeaderUserText = styled.div.attrs({
})`           
    font-size: 15px;
    font-weight: bold;
    color: ${props => props.theme.palette.colors.grey};
    margin-bottom: 12px; 
`;

export const DashboardMenuOption = styled.div.attrs({
})`           
    color: ${props => props.theme.palette.colors.grey};
    cursor: pointer; 
    padding: 16px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: left;
    border-radius: 8px;
    transition: all 0.3s ease;
    margin-bottom: 4px;
    &:hover {
        background: ${props => props.theme.palette.secondary.main};
        color: ${props => props.theme.palette.primary.main};
    }
    ${props => props.active ? `
            background: ${props.theme.palette.secondary.main};
            color: ${props.theme.palette.primary.main};
        ` : ``
    }
`;

export const DashboardMenuContent = styled.div.attrs({
})` 
    width: 100%;
    border-top: 1px solid ${props => props.theme.palette.colors.backgroundgrey};
    padding: 20px 0;
    border-bottom: 1px solid ${props => props.theme.palette.colors.backgroundgrey};
`;

export const DashboardMenuFooter = styled.div.attrs({
})`
margin-top: 8px;

`;

export const DashboardVersionContent = styled.div.attrs({
})` 
    margin: 24px 0;
`;

export const DashboardVersionText = styled.div.attrs({
})`
    font-size: 15px;
    font-weight: bold;
    color: ${props => props.theme.palette.colors.grey};
    text-align: center; 
`;
