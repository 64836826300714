import styled from 'styled-components'

export const DashboardPage = styled.div.attrs({
})`            
    overflow: auto;
    background: ${props => props.theme.palette.colors.almostwhite};

`;

export const DashboardBody = styled.div.attrs({
})`       
    min-height: calc(100vh - 88px);
    max-height: calc(100vh - 88px);
    display: flex;
    align-items: flex-start;     
    justify-content: flex-end;
    ${props => props.noHeader ? `
        min-height: 100vh;
        max-height: 100vh;
        ` : ``
    };

`;

export const DashboardBodyContent = styled.div.attrs({
})`            
    width:${props => props.saying ? '100%' : 'calc(100% - 252px)'};
    border-radius: 11px;
    min-height: calc(100vh - 136px);
    @media (max-width: 991px) {
        width: 100%;
    }
`;

export const Content = styled.div.attrs({
})`           
    overflow:hidden;
`;

export const HeaderContent = styled.div.attrs({
})`
    display: flex;
    justify-content: flex-end;
`;