import React, { useContext, useEffect, useState } from "react";

import {
    MoreInfosContainer,
    MoreInfosText,
    MoreInfosTitle,
    MoreInfosTitleContent,

} from './styled'
import VideoPlayer from "components/VideoPlayer";
import { Icon } from "ui/styled";
import useI18n from "hooks/useI18n";
import { CoreContext } from "context/CoreContext";


export default function MoreInfos() {

    const { t } = useI18n(false)
    const {language} = useContext(CoreContext)
    const [url, setUrl] = useState('')

    const test_url = `/videos/landpage_${language.toLowerCase()}.mp4`

    useEffect(() => {
      fetch(test_url, { method: 'GET' })
          .then((response) => {
              if (response.status === 404) {
                  setUrl(`/videos/landpage_english.mp4`);
              } else {
                  const contentLength = response.headers.get('Content-Length');
                  if (response.ok && contentLength && parseInt(contentLength) > 0) {
                      setUrl(test_url);
                  } else {
                      setUrl(`/videos/landpage_english.mp4`);
                  }
              }
          })
          .catch(() => setUrl(`/videos/landpage_english.mp4`));
  }, [test_url]);

    return (
        <>
            <MoreInfosContainer>
                <MoreInfosTitleContent>
                    <Icon icon="logo-mobdict" />
                    <MoreInfosTitle>
                        { t("landpage_more_title") }
                    </MoreInfosTitle>
                </MoreInfosTitleContent>
                <MoreInfosText>
                    { t("landpage_more_text") }
                </MoreInfosText>
                <VideoPlayer url={url} />

            </MoreInfosContainer>
        </>
    );
}