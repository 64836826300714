import React, { useContext } from 'react'
import { InfoHeaderContainer, InfoHeaderText } from './styled'
import Button from 'components/Form/Button'
import { Icon } from 'ui/styled';
import { CoreContext } from 'context/CoreContext';
import useI18n from 'hooks/useI18n';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

export default function InfoHeader() {

  const { setModal, words, plan, testDrive, setOpened } = useContext(CoreContext);
  const [planExpired, setPlanExpired] = useState(false)

  const history = useHistory();
  const navigate = to => history.push(`/${to}`);

  useEffect(() => {
    if (plan) {
      setPlanExpired(false);
    } else {
      setPlanExpired(true);
    }
  }, [plan])
  
  const { t } = useI18n(false)
  
  // Período de teste: 7 dias restantes ou

  return (
    <>
      <InfoHeaderContainer trialEnded={planExpired} onClick={() => {
        setOpened(false)
        navigate('plans')
        }}>
        <InfoHeaderText>
          {testDrive !== undefined && testDrive !== null && Object.values(testDrive).length === 0 ? `${words?.qtd_ditados} ${ t("dashboard_home_dicts_done") } (${words?.qtd_teste} ${t("dashboard_qtdade_TD")}) / ${words?.qtd_contratado} ${ t("dashboard_home_dicts_plan") }` : 
            !planExpired ? `${ t("dashboard_home_dicts_test") }: ${testDrive?.tempo_restante} ${t("dashboard_home_dicts_days")} / ${testDrive?.ditados_disponiveis} ${t("dashboard_home_dicts_dict")}` : `${ t("dashboard_home_dicts_test_done") }` }
        </InfoHeaderText>
        <Button
          link
          small
          nospace
          color='black'
          endIcon='chevron-right'
        >
          { t("dashboard_home_see_plans") }
        </Button>
      </InfoHeaderContainer>
    </>
  )
}

