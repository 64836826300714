import styled from 'styled-components'

export const HomeContent = styled.div.attrs({
})`
    display: flex;
    width: 100%;
    gap: 24px;
    padding: 24px 40px;
    flex-wrap: wrap;
    @media (max-width: 768px) {
        padding: 24px 16px;
    }
`;

export const ContactContainer = styled.div.attrs({
})`
    display: flex;
    gap: 16px;
    justify-content: ${props => !props.center ? ' space-between' : 'center'};
    width: 100%;
    border-radius: 8px;
    background-color: ${props => props.theme.palette.colors.white};
    padding: 16px;
    flex-wrap: wrap;

`;

export const InfoName = styled.div.attrs({
})`
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 48px;
    text-align: center;

    color: ${props => props.theme.palette.colors.black};
`;