import React, { useEffect, useState } from 'react'
import { Answer, CommonQuestionsContent, CommonTitle, FaqsContainer, Question, QuestionIcon, Text, TextAnswer } from './styled'

import { Icon } from 'ui/styled'
import useI18n from 'hooks/useI18n'
import { ReadFaq } from 'services/faqApl'
import { exposeMobdictError } from 'utils'

export default function CommonQuestionsApl({ faq }) {
  const [activeQuestions, setActiveQuestions] = useState([])

  const { t, language } = useI18n(false)

  const handleQuestionClick = (id) => {
    if (activeQuestions.includes(id)) {
      setActiveQuestions(activeQuestions.filter(questionId => questionId !== id))
    } else {
      setActiveQuestions([...activeQuestions, id])
    }
  }

  const [faqs, setFaqs] = useState([])
  
  // [
  //   {
  //     id: 1,
  //     question: t("faq_question_1"),
  //     answer: t("faq_answer_1")
  //   },
  //   {
  //     id: 2,
  //     question: t("faq_question_2"),
  //     answer: t("faq_answer_2")
  //   },
  //   {
  //     id: 3,
  //     question: t("faq_question_3"),
  //     answer: t("faq_answer_3")
  //   },
  //   {
  //     id: 4,
  //     question: t("faq_question_4"),
  //     answer: t("faq_answer_4")
  //   },
  // ];

  const init = async () => {
    if(language){
      const result = await ReadFaq(language)
      if(!exposeMobdictError(result, !!result?.data?.faqs?.length )){
        setFaqs(( result?.data?.faqs || [])?.map((m,k)=> ({
          id: (k+1),
          question: m?.pergunta,
          answer: m?.resposta,
        })))
      }
      // console.log("FAQ RESULT", result)
    }
  }

  useEffect(() => {
    init()
  }, [language])


  return (
    <>
      <CommonQuestionsContent faq={faq}>
        <CommonTitle faq={faq} ><Icon icon={'logo-mobdict'} width={44} nomargin /> { t("faq_title") }</CommonTitle>
        {
          faqs?.map((item) => (
            <FaqsContainer key={item.id}>
              <Question faq={faq} onClick={() => handleQuestionClick(item.id)} open={activeQuestions.includes(item.id)}>
                <Text faq={faq}>{item.question}</Text>
                <QuestionIcon faq={faq} open={activeQuestions.includes(item.id)} />
              </Question>
              {
                activeQuestions.includes(item.id) && (
                  <Answer faq={faq}>
                    <TextAnswer faq={faq}>{item.answer}</TextAnswer>
                  </Answer>
                )
              }
            </FaqsContainer>

          ))
        }
      </CommonQuestionsContent>

    </>
  )
}