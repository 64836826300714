import styled from 'styled-components';

export const VideoWrapper = styled.div.attrs({
})`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledVideo = styled.video.attrs({

})`
  width: ${props => props.width ? props.width : '100%'};
  object-fit: contain;
  ${props => props.dash ? 'border-top-left-radius: 8px; border-top-right-radius: 8px;' : 'border-radius: 8px;'}
`;