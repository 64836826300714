import styled from "styled-components";

export const IconContent = styled.div.attrs({
})`
    display: flex;
    align-items: center;
    gap: 8px;
`;

export const DashboardMenuHeader = styled.div.attrs({
})`           
    display: flex;
    gap: 16px;
    align-items: center;
`;
