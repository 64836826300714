import styled from "styled-components";

export const SayingContainer = styled.div.attrs({
})`
  width: 100%;
  height: 100%;
  padding: 0 40px;
  @media (max-width: 768px) {
    padding: 0 16px;
  }
`;

export const SayingContent = styled.div.attrs({
})`
    width: 100%;
    padding: 24px 24px 48px 24px;
    min-height: 846px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: ${props => props.center ? 'center' : 'space-between'};
    background: ${props => props.theme.palette.colors.white};
    @media (max-width: 507px) {
      margin-top: 54px;
    }
`;

export const Label = styled.div.attrs({
})`
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  color: ${props => props.theme.palette.colors.grey};
`;

export const SayingBody = styled.div.attrs({
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
`;

export const SayingBodyContent = styled.div.attrs({
})`
   display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 16px;
  margin-top: 50px;
`;

