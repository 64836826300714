import styled from 'styled-components'

export const FormTitle = styled.div.attrs({
})`           
    color: ${props => props.theme.palette.primary.main}; 
    font-weight: bold;
    font-size: 20px; 

    margin: 50px 0 10px;
`;
export const FormText = styled.div.attrs({
})`           
    font-size: 15px; 
    color: ${props => props.theme.palette.colors.grey};
    margin-bottom: 30px;
    margin-left: 53px;
    ${props => props.center ? `
        text-align: center;
        `: ``};
`;

export const FormSpacer = styled.div.attrs({
})`           
    margin-top: ${props => props.marginTop ? props.marginTop : '24px'};

    ${props => props.border ? `
        margin-bottom: 24px;
        border-bottom: 1px solid ${props.theme.palette.colors.backgroundgrey};
        `: ``};
`;

export const RegisterCall = styled.div.attrs({
})`           
    margin: 30px 0 0px;
    color: ${props => props.theme.palette.colors.grey};
    font-size: 15;
    font-weight: bold;
`;

export const RegisterContent = styled.div.attrs({
})`
    background: ${props => props.theme.palette.colors.white};
    max-width: 676px;
    padding: 32px 40px;
    border-radius: 8px;
    flex: 1;
    @media(max-width: 767px){
         max-height: 100%;
         padding: 32px 20px;
    }
`;

export const RegisterContainer = styled.div.attrs({
})`
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0 20px;
    margin-top: 60px;
    margin-bottom: 60px;
`;

export const RegisterFormContent = styled.div.attrs({
})`
    display: flex;
    width: 100%;
    gap: 24px;
    @media(max-width: 630px){
        flex-direction: column;
        gap: 16px;
    }
`;

export const SubGenTitle = styled.div.attrs({
})`           
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    align-items: center;
    font-family: Poppins;
    font-size: ${props => props.small ? `20px` : `24px`};
    font-weight: 700;
    text-align: ${props => props.center ? `center` : `left`};
    color: ${props => props.theme.palette.primary.main};

    ${props => props.hasIcon ? `
        display: flex;
        align-items: flex-start;
        gap: 8px;
        flex-wrap: wrap;
        ` : ``}
`;

export const Subtitle = styled.div.attrs({
})`
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 41px;
    text-align: left;
    color: ${props => props.theme.palette.primary.main};
`;


export const Text = styled.div.attrs({
})`
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;

`;

export const Link = styled.a.attrs({
})`
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: ${props => props.theme.palette.primary.main}; 

    cursor: pointer;
`;
export const CheckContainer = styled.div.attrs({
})`
    background: ${props => props.theme.palette.colors.almostwhite};
    padding: 16px 8px;
    border-radius: 4px;
    margin-bottom: 8px;
`;
export const SuccessContent = styled.div.attrs({
})`
    background: ${props => props.theme.palette.colors.white};
    padding: 40px;
    border-radius: 8px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const PasswordContent = styled.div.attrs({
})`
    background: ${props => props.theme.palette.colors.white};
    max-width: 676px;
    border-radius: 8px;
    flex: 1;
`;