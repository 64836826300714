import styled from 'styled-components'

export const HomeContent = styled.div.attrs({
})`
    display: flex;
    width: 100%;
    gap: 24px;
    padding: 24px 40px;
    flex-wrap: wrap;
    @media (max-width: 768px) {
        padding: 24px 16px;
    }
`;

export const FormTitle = styled.div.attrs({
})`           
    color: ${props => props.theme.palette.primary.main}; 
    font-weight: bold;
    font-size: 20px; 

    margin: 50px 0 10px;
`;
export const FormText = styled.div.attrs({
})`           
    font-size: 15px; 
    color: ${props => props.theme.palette.colors.grey};
    ${props => props.center ? `
        text-align: center;
        `: ``};
`;

export const FormSpacer = styled.div.attrs({
})`           
    margin-top: ${props => props.marginTop ? props.marginTop : '24px'};

    ${props => props.border ? `
        margin-bottom: 24px;
        border-bottom: 1px solid ${props.theme.palette.colors.backgroundgrey};
        `: ``};
`;

export const CheckoutCall = styled.div.attrs({
})`           
    margin: 30px 0 0px;
    color: ${props => props.theme.palette.colors.grey};
    font-size: 15;
    font-weight: bold;
`;

export const CheckoutContentBackground = styled.div.attrs({
})`
    background: ${props => props.theme.palette.colors.ligtwhite};
    width: 100%;
    border-radius: 8px;
    padding: 16px 16px 32px 16px;
    display: flex;
    gap: 24px;
    justify-content: space-between;
    flex-wrap: wrap;
`;

export const CheckoutContent = styled.div.attrs({
})`
    background: ${props => props.theme.palette.colors.white};
    max-width: 800px;
    padding: 32px 40px;
    border-radius: 8px;
    flex: 1;
    @media(max-width: 767px){
         max-height: 100%;
         padding: 32px 20px;
    }
`;

export const CheckoutContainer = styled.div.attrs({
})`
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0 20px;
    margin-top: 60px;
    margin-bottom: 60px;
`;

export const CheckoutFormContent = styled.div.attrs({
})`
    display: flex;
    width: 100%;
    flex-direction: ${props => !props.row ? 'column' : 'row'};
    gap: 16px;
`;

export const CheckItems = styled.div.attrs({
})`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    @media(max-width: 410px){
        grid-template-columns: repeat(2, 1fr);
    }
`;

export const CheckItem = styled.div.attrs({
})`

`;


export const Text = styled.div.attrs({
})`
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;

`;

export const Link = styled.a.attrs({
})`
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: ${props => props.theme.palette.colors.black}; 
`;
export const CheckContainer = styled.div.attrs({
})`
    background: ${props => props.theme.palette.colors.almostwhite};
    padding: 16px 8px;
    border-radius: 4px;
    margin-bottom: 8px;
`;
export const SuccessContent = styled.div.attrs({
})`
    background: ${props => props.theme.palette.colors.white};
    padding: 40px;
    border-radius: 8px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const SummaryContent = styled.div.attrs({
})`
    display: flex;
    flex-direction: column;
    gap: 16px;
    max-width: 577px;
    width: 100%;
    height: fit-content;
    background: ${props => props.theme.palette.colors.white};
    border-radius: 8px;
    padding: 16px;
`;

export const SummaryItems = styled.div.attrs({
})`
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 20px 0;
    border-bottom: 1px solid ${props => props.theme.palette.colors.backgroundgrey};
    border-top: 1px solid ${props => props.theme.palette.colors.backgroundgrey};
`;

export const SummaryItem = styled.div.attrs({
})`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const SummaryItemText = styled.div.attrs({
})`
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 16px;

    color: ${props => props.theme.palette.colors.black};
`;
