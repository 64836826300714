import React from 'react';
import { highlightText } from 'utils/functions';


export default function HighlightedText({ error }) {
  if(!error) return null;
  const { text, words, backgroundColor, underline } = error;
  // Use the highlightText function to get the highlighted HTML
  const highlightedText = highlightText({ text, words, backgroundColor, underline });

  return (
    <div dangerouslySetInnerHTML={{ __html: highlightedText }} />
  );
}