import React, { useState } from 'react'

import { Container } from 'reactstrap';
import { useHistory } from 'react-router-dom';

import {
    HeaderContainer,
    Logo,
    IconContent,
    HeaderContent
} from './styled';

import {

    Icon
} from 'ui/styled';
import Button from 'components/Form/Button';
import Select from 'components/Form/Select';
import { languages, optionsLanguage } from 'utils/options';
import useI18n from 'hooks/useI18n';


export default function Header() {

    const history = useHistory();
    const navigate = to => history.push(`/${to}`);

    const {
        t,
        language,
        setLanguage
    } = useI18n()



    return (
        <>
            <HeaderContainer>
                <Logo onClick={() => navigate('')}/>
                <HeaderContent>
                    <Button secondary outline small nospace colorBlack noWidth onClick={() => navigate('login')}>
                        {/* Já tenho uma conta */}
                        { t('login') }
                    </Button>
                    <Button noWidth secondary small nospace onClick={() => navigate('register')}>
                        {/* Começar agora */}
                        { t('lg-acc') }
                    </Button>
                    <Select
                        small
                        fitContent
                        border
                        startIcons={
                            <IconContent>
                                <Icon icon='mundi' />
                                {/* <Icon icon={ `${language}`.toLowerCase() } /> */}
                            </IconContent>
                        }
                        width={'155px'}
                        placeholder='Português'
                        options={optionsLanguage}
                        value={language}
                        onChange={setLanguage}
                    />
                </HeaderContent>
            </HeaderContainer>
        </>
    )
}