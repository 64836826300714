import React, { useRef } from 'react';
import styled from 'styled-components';
import { Content, MuteButton, PlayButton, StyledVideo, VideoWrapper } from './styled';

const VideoPlayer = ({ url, width, dash }) => {
  const videoRef = useRef(null);

  return (
    <VideoWrapper>
      <StyledVideo
        id = "video-lp"
        ref={videoRef}
        src={url}
        controls
        width={width}
        dash={dash}
        onEnded={() => videoRef.current.play()}
      />
    </VideoWrapper >
  );
};

export default VideoPlayer;