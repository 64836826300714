import React from "react";

import {
    ContactContainer,
    HomeContent,
    InfoName
} from "./styled";

import ContainerSupport from "containers/Support";
import useWindowSize from "utils/useWindowSize";
import ContactUs from "components/ContactUs";
import About from "components/About";

export default function Support() {
    const { width } = useWindowSize()
    // const { setOpened } = useContext(CoreContext)

    // useEffect(() => {
    //     if (width <= 991) {
    //         setOpened(false)
    //     } else {
    //         setOpened(true)
    //     }
    // }, [width])

    return (
        <>
            <ContainerSupport>
                <HomeContent>
                    <ContactContainer>
                        <ContactUs />
                        <About />
                    </ContactContainer>
                    <ContactContainer center>
                        <InfoName>
                            TECHNNI SOFTWARE DEVELOPMENT LTDA - CNPJ: 55.008.821/0001-66
                        </InfoName>
                    </ContactContainer>
                </HomeContent>
            </ContainerSupport>
        </>
    );
}