import React from 'react'
import { Icon, Title } from 'ui/styled'
import { AbountContent, AboutContainer, AboutText, ContactContainer, ContactUsContent, ContactUsFormTitle, ContactUsFormValues } from './styled'
import useI18n from 'hooks/useI18n'

export default function About() {
  
  const { t } = useI18n(false)

  return (
    <>
      <AbountContent>
        <Title hasIcon>
          <Icon icon='logo-mobdict' width={44} nomargin />
          { t("dashboard_talkus_about_title") }
        </Title>
        <AboutContainer>
          <AboutText>
          { t("dashboard_talkus_about_text") }
          </AboutText>
        </AboutContainer>
      </AbountContent>
    </>
  )
}
