import styled from 'styled-components'

export const HomeContent = styled.div.attrs({
})`
    display: flex;
    width: 100%;
    gap: 24px;
    padding: 24px 40px;
    flex-wrap: wrap;
    @media (max-width: 768px) {
        padding: 24px 16px;
    }
`;