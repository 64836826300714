import useI18n from "hooks/useI18n";
import {
  GooglePlayButton,
  AppStoreButton,
} from "react-mobile-app-button";
import './style.css';

export default function DownloadButton({ type, width }) {
  const { t } = useI18n();

  const IOSUrl = "https://apps.apple.com/br/app/mobdict-image-sender/id6569225910"
  const GoogleUrl = "https://play.google.com/store/apps/details?id=com.mobdict.app&hl=pt_BR"

  return (
    <>
      {type === "apple" ? (
        <AppStoreButton
          url={IOSUrl}
          theme={'dark'}
          title={t("landpage_download_button")}
          className={"button"}
          width={width}
        />
      ) : (
        <GooglePlayButton
          url={GoogleUrl}
          theme={'dark'}
          title={t("landpage_download_button")}
          className={"button"}
          width={width}
        />
      )}
    </>
  );
}