import styled from 'styled-components'

export const HomeContent = styled.div.attrs({
})`
    display: flex;
    width: 100%;
    gap: 24px;
    padding: 24px 40px;
    flex-wrap: wrap;
    @media (max-width: 768px) {
        padding: 24px 16px;
    }
`;

export const SettingsContentBackground = styled.div.attrs({
})`
    background: ${props => props.theme.palette.colors.white};
    width: 100%;
    border-radius: 8px;
    padding: 32px 40px;
    @media(max-width: 767px){
        padding: 24px 16px;
    }
`;

export const SettingsContent = styled.div.attrs({
})`
    background: ${props => props.theme.palette.colors.white};
    width: 620px;
    border-radius: 8px;
    @media(max-width: 767px){
         max-height: 100%;
         width: 100%;
    }
`;


export const FormSpacer = styled.div.attrs({
})`           
    margin-top: ${props => props.marginTop ? props.marginTop : '24px'};

    ${props => props.border ? `
        margin-bottom: 24px;
        border-bottom: 1px solid ${props.theme.palette.colors.backgroundgrey};
        `: ``};
`;

export const SettingsOptions = styled.div.attrs({
})`
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
`;

export const SettingsOption = styled.div.attrs({
})`
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 56px;
    @media(max-width: 721px){
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
    }
        
`;

export const SettingsOptionTitle = styled.div.attrs({
})`
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    border-radius: 8px 0px 0px 8px;
    background: ${props => props.theme.palette.colors.backgroundgrey};
    height: 56px;
    padding: 8px;
    width: 360px;
    display: flex;
    align-items: center;    
    color: ${props => props.theme.palette.colors.grey};
    @media(max-width: 721px){
        width: 100%;
        border-radius: 8px;
    }
`;

export const SettingsOptionValue = styled.div.attrs({
})`
    border-radius: 0px 8px 8px 0px;
    background: ${props => props.theme.palette.colors.almostwhite};
    flex: 1;
    height: 56px;
    padding: 8px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    @media(max-width: 721px){
        width: 100%;
        border-radius: 8px;
    }
`;

export const SettingsOptionRange = styled.div.attrs({
})`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    width: 100%;
`;


export const RangeLabel = styled.div.attrs({
})`
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    text-align: left;

    color: ${props => props.theme.palette.colors.black};
`;

export const Input = styled.input.attrs({
})`
`;