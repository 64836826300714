import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { LightTooltip } from "./styled";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export const HelpIcon = ({ helpIcon, fontSize, style, icon }) => {
    return (
        <LightTooltip title={helpIcon} placement="right-start">
          {icon === "exclamation" ? 
            (<ErrorOutlineIcon fontSize={fontSize} style={style}/>) :
            (<HelpOutlineIcon fontSize={fontSize} style={style}/>)
          }
        </LightTooltip>
    );
}