import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  mode: 'light',
  palette: {
    primary: {
      main: '#0A2854',
      contrastText: '#fff',
      light: '#FFFFFF4D',
    },
    audio: {
      main: '#0A28549C',
      contrastText: '#fff',
    },
    secondary: {
      main: '#00DCCF',
      contrastText: '#000',
    },
    tertiary: {
      main: '#4285F4',
      contrastText: '#ffffff',
    },
    white: {
      main: '#ffffff',
      contrastText: '#06346b',
    },
    error: {
      main: '#FF1515',
    },
    black: {
      main: '#000000',
      contrastText: '#fff',
    },
    warning: {
      main: '#ffa726',
    },
    info: {
      main: '#a4a4a4',
      contrastText: '#fff',
    },
    success: {
      main: '#66bb6a',
      contrastText: '#fff',
    },
    wrong: {
      main: 'rgba(255, 0, 46, 0.15)',
      secondary: 'rgba(255, 0, 46, 0.05)',
    },
    right: {
      main: 'rgba(130, 203, 237, 0.5)',
      secondary: 'rgba(130, 203, 237, 0.15)',
    },
    challenge: {
      main: 'rgba(175, 177, 250, 0.5)',
      contrastText: '#000',
    },
    plans: {
      Free: 'rgba(177, 215, 0, 0.4)',
      Motivado: 'rgba(197, 239, 0, 1)',
      Dedicado: 'rgba(255, 199, 22, 1)',
      Apaixonado: 'rgba(175, 177, 250, 1)',

      0: 'rgba(177, 215, 0, 0.4)',
      1: 'rgba(197, 239, 0, 1)',
      2: 'rgba(255, 199, 22, 1)',
      3: 'rgba(175, 177, 250, 1)',

    },
    colors: {
      white: '#ffffff',
      character: '#F2F2F7',
      avocado: '#C5EF00',
      black: '#000000',
      end: '#F6DADA',
      grey: '#4C4C4C',
      backgroundgrey: '#E8E8E8',
      almostwhite: '#FAFAFA',
      ligtwhite: '#F5F5F5',
      lightgrey: '#A4A4A4',
      lightgreen: '#E6FBFA',
      background: 'rgba(255, 199, 22, 0.05) ',
      backgroundBanner3: 'rgba(134, 140, 193, 0.05)',
      overlay: 'rgba(0, 0, 0, 0.6)',
      question: 'rgba(255, 255, 255, 0.1)',
      shadow: 'rgba(0,0,0,.16)',
      lightshadow: 'rgba(112,112,112,.06)',


    }
  },
});

