import styled from "styled-components";

export const HomeBodyContainer = styled.div.attrs({
})`
  background: ${props => props.theme.palette.colors.white};
  border-radius: 24px;
  max-width: 760px;
  flex: 1;
  gap: 16px;
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

export const InfoStudyingIcon = styled.img.attrs({
})`
  max-width: 430px;
  flex: 1;
  margin: 0 auto;
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;


export const FormBorder = styled.div.attrs({
})`
  border-bottom: 1px solid ${props => props.theme.palette.colors.backgroundgrey};
  width: 100%;
`;

export const ButtonContent = styled.div.attrs({
})`
  width:'320px';
  @media (max-width: 768px) {
    width: 100%;
  }
`;