import { CoreContext } from "context/CoreContext";
import useI18n from "hooks/useI18n";
import { useContext } from "react";
import { ButtonClose, PaymentsContainer, PaymentItem, ModalBody, ModalContainer, ModalContent, ModalLabel, ModalTitleContainer, Overlay, Subtitle } from './styled'
import { Icon, Title } from 'ui/styled'
import PixIcon from '@mui/icons-material/Pix';
import CreditCardIcon from '@mui/icons-material/CreditCard';

export default function Payments() {
  const { setModal, modal, user, fetchUser, language } = useContext(CoreContext)

  const { t } = useI18n(false)

  return (
    <>
      <Overlay onClick={() => setModal(null)} />
      <ModalContainer>
        <ModalContent>
          <ModalTitleContainer>
            <Title hasIcon>
              <Icon icon='logo-mobdict' width={44} nomargin />
              { t("plans_form_payments") }
              {/* <Subtitle>
                ({ t("dashboard_subtitle_payments") })
              </Subtitle> */}
            </Title>
            <ButtonClose>
              <Icon icon='close-black' pointer onClick={() => setModal(null)} />
            </ButtonClose>
          </ModalTitleContainer>
          <ModalBody>
            <PaymentsContainer>
              <PaymentItem>
                <PixIcon fontSize="large" />
                <Subtitle>
                  PIX
                </Subtitle>
              </PaymentItem>
              <PaymentItem>
                <CreditCardIcon fontSize="large" />
                <Subtitle>
                  { t("plans_payment_card") }
                </Subtitle>
              </PaymentItem>
            </PaymentsContainer>
          </ModalBody>

        </ModalContent>
      </ModalContainer>
    </>
  )
}