
import styled from 'styled-components';

export const CustomModal = styled.div`
  display: ${(props) => (props.isOpen ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  padding: 24px;
`;

export const ModalContent = styled.div.attrs({
})`
  display: flex;
  flex-direction: column;
  max-width: 700px;
  width: 100%;
  padding-bottom: 24px;

  align-items: center;
  background: ${props => props.theme.palette.colors.white};
`;

export const ModalTitleContainer = styled.div.attrs({
})`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 48px 8px 48px;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.palette.colors.backgroundgrey};

`;

export const ModalImage = styled.div.attrs({
})`

  max-width: 604px;
  width: 100%;
  height: 520.5px;
  background: url(${props => props.src}) no-repeat center center / contain;
  margin-top: 8px;
  
`;


export const ButtonClose = styled.div.attrs({
})`
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.24px;
  color: ${props => props.theme.palette.colors.black};
  margin-right: -8px;
  cursor: pointer;

`;