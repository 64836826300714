import styled from "styled-components";

export const InfoHeaderContainer = styled.div.attrs({
})`
    display: flex;
    align-items: center;
    height: 40px;
    background: ${props => props.trialEnded ? props.theme.palette.colors.end : props.theme.palette.colors.lightgreen};
    padding: 0 16px;
    gap: 16px;
    border-radius: 8px;
    &:hover {
        cursor: pointer;
        filter: brightness(0.9);
    }
    @media (max-width: 826px) {
        display: none;
    }
`;

export const InfoHeaderText = styled.div.attrs({
})`
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    white-space: nowrap;
    line-height: 24px;
    text-align: left;
    color: ${props => props.theme.palette.colors.black};
    display: flex;

`;