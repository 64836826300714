import styled from 'styled-components'

export const PlansContent = styled.div.attrs({
})`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    background: ${props => props.theme.palette.primary.main};
    min-height: 90vh;
    position: relative;
    @media(max-width: 767px){
        padding: 0 24px 0;
    }
`;

export const ImageBackground = styled.img.attrs({
})`
    width: 100%;
    height: 100%;
    position: absolute;
    margin-top: 21px;
    z-index: 0;
    max-width: 1477px;
    @media(max-width: 991px){
        display: none;
    }
`;