import React, { useEffect } from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation
} from "react-router-dom";

import Landpage from 'screens/Landpage'
import NotFound from 'screens/NotFound'
import Plans from 'screens/Plans'
import Support from 'screens/Support'

import Login from 'screens/Authentication/Login'
import Register from 'screens/Authentication/Register'
import Forgot from 'screens/Authentication/Forgot'
import CreatePassword from 'screens/Authentication/CreatePassword'

import DashboardHome from 'screens/Dashboard/Home'
import Saying from "screens/Saying";
import RankingDashboard from "screens/Ranking";
import Profile from "screens/Profile";
import Settings from "screens/Settings";
import FAQ from "screens/FAQ";
import Contact from "screens/Contact";
import Checkout from "screens/Checkout";
import Tutorial from "screens/Tutorial";
import ModalCore from "components/Modal/Core";
import SayingReinforcement from "screens/SayingReinforcement";
import TagManager from 'react-gtm-module';

const TrackPageView = () => {
  const location = useLocation();

  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'pageview',
        page: location.pathname,
      },
    };
    TagManager.dataLayer(tagManagerArgs);
  }, [location]);

  return null;
};

export default function AppRouter() {

  return (
    <Router>
      <TrackPageView />
      <ModalCore />
      <div>
        <Switch>
          <Route path="/" exact> <Landpage /> </Route>
          <Route path="/plans" exact> <Plans /> </Route>
          <Route path="/support" exact> <Support /> </Route>

          <Route path="/login" exact> <Login /> </Route>
          <Route path="/register" exact> <Register /> </Route>
          <Route path="/forgot" exact> <Forgot /> </Route>
          <Route path="/create-password" exact> <CreatePassword /> </Route>

          <Route path="/dashboard" exact> <DashboardHome /> </Route>
          <Route path="/saying" exact> <Saying /> </Route>
          <Route path="/saying-reinforcement" exact> <SayingReinforcement /> </Route>
          <Route path="/ranking" exact> <RankingDashboard /> </Route>
          <Route path="/profile" exact> <Profile /> </Route>
          <Route path="/settings" exact> <Settings /> </Route>
          <Route path="/faq" exact> <FAQ /> </Route>
          <Route path="/contact" exact> <Contact /> </Route>
          <Route path="/checkout" exact> <Checkout /> </Route>
          <Route path="/tutorial" exact> <Tutorial /> </Route>

          <Route path="*" exact> <NotFound /> </Route>
        </Switch>
      </div>
    </Router>
  );
}