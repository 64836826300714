import styled from "styled-components";

export const ResponseContent = styled.div.attrs({
})`
  min-height: 95px;
  width: 100%;
  border-radius: 8px;
  padding: 16px 26px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 75px;
  transition: background 0.5s ease;
  @media (max-width: 668px) {
    flex-direction: column;
  }
`;


export const ResponseTitle = styled.div.attrs({
})`
  font-family: Poppins;
  font-size: 16px;
  font-weight: 700;
  line-height: 48px;
  text-align: center;
  color: ${props => props.theme.palette.primary.main};

`;
export const ResponseText = styled.div.attrs({
})`
  font-family: Poppins;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 24px;
  text-align: center;

  color: ${props => props.theme.palette.colors.black};
`;

export const ResponseButton = styled.div.attrs({
})`
  
`;