import React, { useContext, useMemo } from 'react'
import { ButtonContainer, Label, SayingBody, SayingBodyContent } from './styled'
import { Container } from 'reactstrap'
import { Title } from 'ui/styled'
import AudioPlayer from 'components/Audio'
import Button from 'components/Form/Button'
import { CoreContext } from 'context/CoreContext'
import useI18n from 'hooks/useI18n'

export default function  NewSaying({ audioDict, audioOriginal }) {

  const { t } = useI18n(false)

  const { reinforcement, challenge, dict, toCorrect, form, setForm } = useContext(CoreContext)
  const array = [
    {},
    {},
    {},
    {},
    {},
  ]

  const formValue = ref => { return form?.[ref] ? form?.[ref] : ''; }
  const changeForm = (value, ref) => { setForm({ ...form, [ref]: value }); }

  const addTextChallange = c => {
    changeForm(`${ formValue('text') }${c}`, 'text')
  }



  const getMarkedWords = (text) => {
    const regex = /\*\*(.*?)\*\*/g;
    let matches = [];
    let match;

    while ((match = regex.exec(text)) !== null) {
        matches.push(match[1]); 
    }

    return matches;
  }

  const challangeWords = useMemo(() => {
    const mw = (getMarkedWords(challenge?.resposta)||[]).sort(() => Math.random() - 0.5)
    console.log("challenge", challenge, mw)
    return (mw ||[])
  }, [challenge])

  return (
    <>
      <SayingBody>
        <SayingBodyContent>
          <Container>
            <Title center small={reinforcement || challenge}>
              {challenge ? t("dashboard_dict_wrong_challange") : reinforcement ? t("dict_reinforcement") : t("dict_a_newdict") }
            </Title>
            {challenge ? <Title center small={reinforcement || challenge}>
              { t("dict_word_bellow") }
            </Title> : null}

            { (!toCorrect || !!audioOriginal) || challenge ? null : <Label>{ toCorrect?.texto_reforco }</Label> }

            {reinforcement || challenge ? null : <Label>
              { t("dict_by") } { dict?.voz }
            </Label>}
          </Container>
          {challenge ? <>
            <ButtonContainer>
              {
                (challangeWords||[])?.map((item, index) => (
                  <Button width='170px' color='challenge' small nospace onClick={() => addTextChallange(item)}>
                    { item }
                  </Button>
                ))
              }
            </ButtonContainer>
          </> : <>
              {
                !dict ? null : <>
                  { !audioOriginal?.url ? null  : <AudioPlayer src={audioOriginal?.url} reinforcement={reinforcement} />}
                  {(reinforcement||!audioDict?.url) ? null : <AudioPlayer src={audioDict?.url} saying />}
                </>
              }
            </>
          }

        </SayingBodyContent>
      </SayingBody>
    </>
  )
}
