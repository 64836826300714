export const static_translation = {
    "lg-acc": "Registrieren",
    "login": "Einloggen",
    "landpage_banner_balloon": "Verbessere eine neue Sprache!",
    "landpage_flags_title": "Verbessere eine Sprache mit Mobdict®",
    
    "landpage_how_works_title": "Unsere größte Freude ist es, beim Erlernen einer neuen Sprache zu helfen ❤️",
    "landpage_how_works_text": "Wir sind leidenschaftlich an Sprachen und Bildung interessiert. Unser Ziel ist es, das Lernen für alle zugänglich und unterhaltsam zu machen, indem wir einen innovativen Ansatz auf Basis von Diktaten nutzen.",
    "landpage_how_works_action": "Ich möchte mehr lernen, lass uns anfangen",
    
    "landpage_learn_title": "Lerne viel mehr 😎",
    "landpage_learn_text": "Unsere Lehrmethode konzentriert sich auf das Üben von Sprachen durch Diktate, eine Technik, die Hörverständnis, Lesen und Schreibpraxis kombiniert, um deine Sprachkenntnisse auf natürliche und effektive Weise zu verbessern.",
    "landpage_learn_action": "Jetzt starten",
    
    "landpage_write_title": "Handschriftlich schreiben und mehr mit Mobdict® lernen",
    "landpage_write_text": "Handschriftliches Schreiben kann die Behaltens- und Verständnisfähigkeit des Wortschatzes verbessern, da es tiefere kognitive Prozesse einbezieht. Reiche deine handschriftlichen Notizen über die Mobdict® Image Sender App ein.",
    
    "landpage_free_title": "Kostenlos testen 🥹",
    "landpage_free_text": "Du kannst die Sprache, die du gerade lernst, auf deinem aktuellen Niveau testen, indem du Mobdict® eine Woche lang kostenlos ausprobierst und bis zu 7 Diktate machst, um zu sehen, wie dein Wortschatz mit jeder Herausforderung wächst.",
    "landpage_free_action": "Ich möchte es ausprobieren",
    
    "landpage_more_title": "Lerne auf die beste Weise 🎬",
    "landpage_more_text": "Sieh dir unser Video an, um zu verstehen, wie das Lernen einer Sprache durch Diktate dir nützen kann.",
    
    "faq_title": "FAQ - Häufig gestellte Fragen",
    "faq_question_1": "Wer kann am Kurs teilnehmen?",
    "faq_answer_1": "Um herauszufinden, wer am Kurs teilnehmen kann, musst du die spezifischen Anforderungen der Institution oder der Kursanbieter überprüfen.",
    "faq_question_2": "Ist unser Kurs für Anfänger und für diejenigen geeignet, die ihre Sprachkenntnisse verbessern möchten?",
    "faq_answer_2": "Unser Kurs ist ideal für Anfänger und für diejenigen, die ihre Sprachkenntnisse verbessern möchten. Daher kann jeder teilnehmen, der anfängt oder seine Sprachfähigkeiten weiterentwickeln möchte.",
    "faq_question_3": "Wie funktioniert die Lernmethode mit Diktaten?",
    "faq_answer_3": "Die Lernmethode mit Diktaten ist eine Technik, die hilft, das Hörverständnis, die Rechtschreibung und das Textverständnis zu verbessern.",
    "faq_question_4": "Wie lange dauert der Kurs?",
    "faq_answer_4": "Die Dauer des Kurses kann je nach Institution, die ihn anbietet, der Gesamtarbeitsbelastung und der Häufigkeit der Kurse variieren.",
    
    "footer_talk_us": "Kontaktiere uns",
    "footer_support": "Support",
    "footer_all_rights": "Alle Rechte vorbehalten",
    
    "lg-google-auth": "Gib den Google Authenticator-Code ein",

    "dashboard_home_see_plans": "Pläne ansehen",
    "dashboard_home_dicts_done": "abgeschlossen",
    "dashboard_home_dicts_plan": "Gekaufte Diktate",
    "dashboard_home_dicts_test": "Testphase",
    "dashboard_home_dicts_days": "verbleibende tage",
    "dashboard_home_dicts_test_done": "Testphase abgeschlossen",
    "dashboard_home_dicts_dict": "Diktate",
    
    "dashboard_home_learning": "Ich lerne",
    "dashboard_home_level": "Niveau",
    "dashboard_home_cta": "Lass uns mit dem Diktat beginnen",
    
    "dashboard_plan_title": "Abonniere einen unserer Pläne",
    "dashboard_plan_subtitle": "jährlich abgerechnet",
    "dashboard_plan_pop": "Beliebtester Plan",
    "dashboard_plan_1": "Basisplan",
    "dashboard_plan_2": "Mittelstufenplan",
    "dashboard_plan_3": "Fortgeschrittenenplan",
    "dashboard_plan_dict": "Diktate",
    "dashboard_plan_action": "Abonnieren",
    "dashboard_plan_currency": "R$",
    
    "dashboard_talkus_title": "Kontaktiere uns",
    "dashboard_talkus_email": "E-Mail",
    "dashboard_talkus_support_email": "Technischer Support",
    "dashboard_talkus_support_address": "Adresse",
    "dashboard_talkus_support_social": "Soziale Medien",
    "dashboard_talkus_about_title": "Über uns",
    "dashboard_talkus_about_text": "Bei Mobdict® ist es einfacher und unterhaltsamer, deine Sprachkenntnisse durch Diktate zu verbessern! Diese kleinen Weisheiten bieten eine einzigartige Möglichkeit, Kultur und Sprache zu verinnerlichen. Während du eine neue Sprache lernst, entdecke Diktate, die die Denk- und Lebensweise der Muttersprachler widerspiegeln. Jeder Ausdruck bringt wertvolle Lektionen und kulturellen Kontext, der dir hilft, Nuancen zu meistern und natürlicher zu klingen.",
    
    "dashboard_home_ranking_title": "Rangliste",
    "dashboard_home_ranking_position": "Pl.",
    "dashboard_home_ranking_name": "Name",
    "dashboard_home_ranking_points": "Punkte",
    "dashboard_home_ranking_see_more": "Mehr anzeigen",

    "dashboard_side_home": "Startseite",
    "dashboard_side_ranking": "Rangliste",
    "dashboard_side_buy": "Kredite kaufen",
    "dashboard_side_account": "Mein Konto",
    "dashboard_side_settings": "Einstellungen",
    "dashboard_side_faq": "FAQ",
    "dashboard_side_talk_us": "Kontaktiere uns",
    "dashboard_side_exit": "Abmelden",
    
    "dashboard_form_password_validation": "Neues Passwort und Bestätigung stimmen nicht überein",

    "dashboard_form_name": "Vollständiger Name",
    "dashboard_form_nickname": "Wähle deinen Benutzernamen",
    "dashboard_form_email": "E-Mail",
    "dashboard_form_phone": "Handy",
    "dashboard_form_password": "Passwort",
    "dashboard_form_level": "Niveau",
    "dashboard_form_confirm_password": "Passwort bestätigen",

    "dashboard_form_account_title": "Mein Konto",
    "dashboard_form_password_title": "Passwort ändern",
    
    "dashboard_form_cancel": "Abbrechen",
    "dashboard_form_next": "Weiter",
    "dashboard_form_save": "Speichern",
    "dashboard_form_subgender": "Wähle Subgenres",
    
    "dashboard_settings_title": "Einstellungen",
    "dashboard_settings_2fa": "Zwei-Faktor-Authentifizierung aktivieren",
    "dashboard_settings_adjust_pause": "Pause beim Diktat anpassen",
    "dashboard_settings_pause": "Pause",
    "dashboard_settings_subgender": "Bevorzugte Subgenres zurücksetzen (min. 3):",
    "dashboard_settings_define": "Festlegen",
    "dashboard_settings_default_lang": "Standardsprache",
    "dashboard_settings_native_lang": "Muttersprache",
    "dashboard_settings_learn_lang": "Zielsprache",
    "dashboard_settings_recurrency": "Wiederkehrendes Abonnement",
    
    
    "dashboard_dict_congrats": "Herzlichen Glückwunsch!",
    "dashboard_dict_added_increase": "Du hast weitere",
    "dashboard_dict_added_words": "Wörter zu deinem Wortschatz hinzugefügt.",
    "dashboard_dict_restart": "Neues Diktat starten",
    "dashboard_dict_back_homepage": "Zur Startseite zurück",
    

    "dashboard_dict_wrong_words": "Wörter sind falsch",
    "dashboard_dict_wrong_omit": "Ausgelassene Wörter",
    "dashboard_dict_wrong_finish": "Beenden",
    "dashboard_dict_wrong_challange": "Herausforderung",
    "dashboard_dict_wrong_reinforcement": "Wiederholung diktieren",
    
    "dashboard_dict_translate": "Übersetzung",
    "dashboard_dict_see_translate": "Übersetzung ansehen",
    
    "dashboard_dict_writing_title": "Text eingeben",
    "dashboard_dict_writing_handed": "Handschriftlich schreiben",
    "dashboard_dict_writing_special": "Sonderzeichen",
    
    "dashboard_dict_writing_step_1": "1. Lade die Mobdict Image Sender App aus dem App Store oder Play Store herunter und melde dich an.",
    "dashboard_dict_writing_step_2": "2. Schreibe das Diktat, das du gehört hast, auf ein Blatt Papier und mache ein Foto.",
    "dashboard_dict_writing_step_3": "3. Reiche das Bild über die App ein und klicke auf den Button, um abzuschließen.",
    "dashboard_dict_writing_step_action": "Ich habe es gesendet",
    "dashboard_dict_writing_step_confirm": "Ich habe es überprüft, Korrigieren",
    
    "dashboard_dict_writing_progress": "Dein Fortschritt",
    "dashboard_dict_writing_meta": "Ziel",
    "dashboard_dict_writing_words": "Wörter",
    
    "dashboard_register_title": "Gib deine Daten ein",
    "dashboard_register_subtitle": "Gib deine Registrierungsdaten an",
    
    "dashboard_password_title": "Erstelle dein Passwort",
    "dashboard_register_level": "Wähle die Sprache und das Niveau",
    "dashboard_register_subgender": "Wähle das Subgenre",
    
    "dashboard_register_terms_1": "Durch die Auswahl und Fortfahren akzeptierst du unsere",
    "dashboard_register_terms_2": "Nutzungsbedingungen",
    "dashboard_register_and": "und",
    "dashboard_register_terms_3": "Datenschutzrichtlinie",
    
    "dashboard_register_validation_not_match": "Neues Passwort und Bestätigung stimmen nicht überein",
    "dashboard_register_validation_accept_terms": "Akzeptiere die Bedingungen",
    "dashboard_register_validation_email": "Gib die E-Mail-Adresse an",
    "dashboard_register_validation_password": "Gib das neue Passwort an",
    "dashboard_register_validation_password_confirmation": "Gib die Bestätigung des neuen Passworts an",
    "dashboard_register_successfull": "Konto erfolgreich erstellt",
    
    "dashboard_register_step1_title": "Gib deine Daten ein",
    "dashboard_register_step1_text": "Gib deine Registrierungsdaten an",
    "dashboard_register_step1_action": "Weiter",
    
    "dashboard_register_step2_title": "Registrierung erfolgreich abgeschlossen",
    "dashboard_register_step2_text": "Vielen Dank für deine Registrierung.",
    "dashboard_register_step2_action": "Schließen",
    "dashboard_register_step2_back": "Zurück",

    "dashboard_forgot_step1_title": "Passwort vergessen?",
    "dashboard_forgot_step1_text": "Gib deine registrierte E-Mail-Adresse ein, um dein Passwort wiederherzustellen",
    "dashboard_forgot_step2_title": "Überprüfe deine E-Mail",
    "dashboard_forgot_step2_text": "Greife auf den Wiederherstellungslink zu, der an deine registrierte E-Mail-Adresse gesendet wurde",
    
    "dashboard_createpassword_step1_title": "Neues Passwort",
    "dashboard_createpassword_step1_text": "Gib ein neues Passwort für dein Konto ein",
    "dashboard_createpassword_step2_title": "Neues Passwort erfolgreich erstellt",
    "dashboard_createpassword_step2_text": "Du kannst dich jetzt mit deinem neuen Passwort anmelden",
    
    "login_mfa": "Scannen Sie mit der Google Authenticator-App oder einer anderen Authentifizierungs-App den folgenden QRCode, um Ihr Token zu generieren",

    "register_validation_subgender": "Wählen Sie mindestens 3 Subgenres aus, um fortzufahren",
    "dict_word_bellow": "Verwenden Sie die folgenden Wörter, um den Text zu vervollständigen",
    "dict_reinforcement": "Wiederholungsdiktat",
    "dict_a_newdict": "Ein neues Diktat starten",
    "dict_by": "Diktiert von",
    "dict_finish": "Fertigstellen",
    "dict_next": "Fortfahren",
    "dict_correct": "Korrigieren",
    
    "Autoajuda": "Selbsthilfe",
    "Biografia": "Biografie",
    "Comédia": "Komödie",
    "Conto": "Kurzgeschichte",
    "Crônica": "Chronik",
    "Ecologia": "Ökologie",
    "Epopeia": "Epos",
    "Fábula": "Fabel",
    "Farsa": "Farce",
    "Ficção": "Fiktion",
    "Infantojuvenil": "Kinder- und Jugendliteratur",
    "Novela": "Novelle",
    "Poesia": "Poesie",
    "Romance": "Roman",
    "Suspense": "Spannung",
    "Tragédia": "Tragödie",
    "True Crime": "Wahre Verbrechen"
}
