import styled from 'styled-components'

export const FormTitle = styled.div.attrs({
})`           
    color: ${props => props.theme.palette.primary.main}; 
    font-weight: bold;
    font-size: 20px; 

    margin: 50px 0 10px;
`;
export const FormText = styled.div.attrs({
})`           
    font-size: 15px; 
    color: ${props => props.theme.palette.colors.grey};
    ${props => props.center ? `text-align: center;` : ''};
    margin-bottom: 30px;
    margin-top: 8px;
`;

export const FormSpacer = styled.div.attrs({
})`           
    margin: 32px 0;
    ${props => props.noBorder ? '' : `
        border-bottom: 1px solid ${props.theme.palette.colors.backgroundgrey};
        `};
`;

export const PasswordContent = styled.div.attrs({
})`
    background: ${props => props.theme.palette.colors.white};
    max-width: 676px;
    border-radius: 8px;
    padding: 40px 40px 40px 40px;
    flex: 1;
`;

export const PasswordContainer = styled.div.attrs({
})`
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 60px;
`;

export const SuccessContent = styled.div.attrs({
})`
    background: ${props => props.theme.palette.colors.white};
    max-width: 676px;
    padding: 100px 40px 100px 40px;
    border-radius: 8px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;