import styled from "styled-components";

export const SuccessContainer = styled.div.attrs({
})`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  flex-direction: column;
  gap: 32px;
`;


export const SuccessContent = styled.div.attrs({
})`
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;