import React from 'react';
import styled from 'styled-components';
import { Container, TableStyled, Tbody, Td, Th, Thead, ThRow } from './styled';



const RightWrongTable = ({ item, items }) => {
  const array = Array(6).fill(0);
  // console.log(array);

  const getCorrectIndexFormValue = it => {
     let val = ""
    //  console.log("item?.correct", item?.correct)
     if(item?.correct){
       Object.keys( item?.correct || {} )?.forEach(k => {
         if( item?.correct[k] === it ){
           val = k
         }
       })
     }
    return val
  }

  return (
    <Container>
      <TableStyled>
        <Thead>
          <ThRow>
            {
              item?.wrong ? <>
                <Th isErrado>Errado</Th>
                <Th>Correto</Th>
              </> : <>
                <Th isErrado>Omitidas</Th>
              </>
            }
          </ThRow>
        </Thead>
        <Tbody>

          {
            item?.wrong ? <>
              {item?.wrong.map((m, index) => (
                <ThRow key={index}>
                  <Td>{ getCorrectIndexFormValue(m) }</Td>
                  <Td>{ m }</Td>
                </ThRow>
              ))}
            </> : <>
              {items.map((m, index) => (
                <ThRow key={index}>
                  <Td>{ m }</Td>
                </ThRow>
              ))}
            </>
          }

        </Tbody>
      </TableStyled>
    </Container>
  );
};

export default RightWrongTable;
