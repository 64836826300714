import styled from "styled-components";

export const InfoStudyingContent = styled.div.attrs({
})`
  display: flex;
  height: 40px;
  border-radius: 8px;
  border: 2px solid ${props => props.theme.palette.secondary.main};
  justify-content: center;
  gap: 16px;
  align-items: center;
  padding: 0 16px;
`;

export const InfoStudyingText = styled.div.attrs({
})`
  font-family: Poppins;
  font-size: 16px;
  font-weight: ${props => props.medium ? '400' : '600'};
  line-height: 24px;
  text-align: left;
  color: ${props => props.theme.palette.primary.main};
  display: flex;
  align-items: center;
  gap: 4px;
`;
export const HomeBodyContent = styled.div.attrs({
})`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 10px;
`;