import styled from "styled-components";

export const PlayerContainer = styled.div.attrs({
})`
  width: 100%;
  display: flex;
  background-color: ${props => props.saying ? props.theme.palette.audio.main : props.theme.palette.primary.main};
  align-items: center;
  border-radius: 40px;
  padding: 16px;
  max-width: 688px;
  gap: 12px;
`;

export const Audio = styled.audio.attrs({
})`
`;
export const PlayButton = styled.div.attrs({
})`
    cursor: pointer;
`;

export const ProgressContainer = styled.div.attrs({
})`
  width: 100%;
  display: flex;

  align-items: center;
`;


export const ProgressInput = styled.input.attrs({})`
  width: 100%;
  cursor: pointer;
  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px; 
    border-radius: 3px; 
  }

  &::-webkit-slider-thumb {
    appearance: none;
    margin-top: -6px; 

  }
`;


export const MuteButton = styled.div.attrs({
})`
    cursor: pointer;

`;

export const FormBorder = styled.div.attrs({
})`
  border: 0.5px solid ${props => props.theme.palette.colors.lightgrey};
  height: 24px;

`;

export const Text = styled.div.attrs({
})`
  color: ${props => props.theme.palette.colors.white};
  font-size: 12px;
  font-weight: 600;
  line-height: 14.52px;
  letter-spacing: -0.01em;
  text-align: left;
  min-width: 45px;

`;