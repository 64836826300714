export function highlightText({ text, words, backgroundColor, underline }) {
  // Escape HTML special characters
  const escapeHtml = (str) => str.replace(/[&<>"']/g, (match) => ({
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#039;'
  }[match]));

  // Escape the text to avoid HTML injection
  text = escapeHtml(text);

  // Process each word with the specified background color
  words.forEach(word => {
    // Escape word for regex usage
    const escapedWord = escapeHtml(word);
    // Create a global regex to match the word
    const regex = new RegExp(`\\b${escapedWord}\\b`, 'gi');
    // Build the replacement HTML with dynamic styles
    let style = '';
    if (backgroundColor) {
      style += `background-color: ${escapeHtml(backgroundColor)}; `;
    }

    // Replace the text with the styled span
    text = text.replace(regex, (match) => {
      const underscores = underline ? '_'.repeat(match.length) : match;
      return `<span style="${style}">${underscores}</span>`;
    });
  });

  return text;
}