import styled from "styled-components";

export const Container = styled.div.attrs({
})`
width: 100%;
max-width: 320px;
`;

export const TableStyled = styled.table.attrs({
})`
    width: 100%;
    border-collapse: collapse;
    border-radius: 8px;
`;

export const Thead = styled.thead.attrs({
})`
`;

export const Tbody = styled.tbody.attrs({
})`
`;

export const Th = styled.th.attrs({
})`
    padding: 10px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    border: 1px solid ${props => props.theme.palette.colors.white};

    ${props => props.isErrado ? `
        background-color: ${props.theme.palette.wrong.main};
        border-radius: 8px 0 0 0;
    ` : `
        background-color: ${props.theme.palette.right.main};
        border-radius: 0 8px 0 0;
    `}
`;

export const Td = styled.td.attrs({
})`
    padding: 8px;
    border: 1px solid ${props => props.theme.palette.colors.white};
    text-align: center;

    &:nth-child(even) {
        background-color: ${props => props.theme.palette.right.secondary};
    }
    &:nth-child(odd) {
        background-color: ${props => props.theme.palette.wrong.secondary};
    }
    &:first-child {
        border-radius: 0 0 0 8px;
    }
    &:last-child {
        border-radius: 0 0 8px 0;
    }
`;


export const ThRow = styled.tr.attrs({
})`
`;