import React, { useRef, useState, useEffect } from 'react';
import { Audio, Controls, FormBorder, MuteButton, PlayButton, PlayerContainer, ProgressContainer, ProgressInput, Text } from './styled';
import { Icon } from 'ui/styled';

const AudioPlayer = ({ src, saying, reinforcement }) => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isMuted, setIsMuted] = useState(false);

  const togglePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      setCurrentTime(audioRef.current.currentTime);
    }
  };

  const handleDurationChange = () => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration);
    }
  };

  const handleProgressChange = (event) => {
    if (audioRef.current && duration) {
      const newTime = (event.target.value / 100) * duration;
      audioRef.current.currentTime = newTime;
      setCurrentTime(newTime);
    }
  };

  const toggleMute = () => {
    if (audioRef.current) {
      audioRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  useEffect(() => {
    const audioElement = audioRef.current;
    if (!audioElement) {
      return;
    }
    audioElement.addEventListener('timeupdate', handleTimeUpdate);
    audioElement.addEventListener('loadedmetadata', handleDurationChange);


    return () => {
      if (audioElement) {
        audioElement.removeEventListener('timeupdate', handleTimeUpdate);
        audioElement.removeEventListener('loadedmetadata', handleDurationChange);
      }
    };
  }, []);

  return (
    <PlayerContainer saying={saying}>
      <Text>
        {saying ? 'Ditado' : reinforcement ? "Ditado" : "Original"}
      </Text>
      <Audio ref={audioRef} src={src} style={{ display: src ? 'block' : 'none' }} />
      <FormBorder />
      <PlayButton onClick={togglePlayPause}>
        <Icon icon={isPlaying ? 'pause' : 'play'} nomargin />
      </PlayButton>
      <ProgressContainer>
        <ProgressInput
          type="range"
          min="0"
          max="100"
          value={duration ? (currentTime / duration) * 100 : 0}
          onChange={handleProgressChange}
        />
      </ProgressContainer>
      <MuteButton onClick={toggleMute}>
        <Icon icon={isMuted ? 'mute' : 'sound'} nomargin />
      </MuteButton>
    </PlayerContainer>
  );
};

export default AudioPlayer;
