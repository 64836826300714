import React, { useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { Col } from 'reactstrap';

import { ReadObject } from "services/storage";
import { ThemedComponent } from "ui/theme";

import {
    SideBackgroundImageContainer,
    FormContent,
    AppLogo,
    Content,
    Touch,
    ColContainer,
    StyledCol,
    ImageBackground,
    StyledColRight
} from './styled'
import { Icon } from "ui/styled";


export default function ContainerUnauthenticated({ children, login }) {

    const history = useHistory();
    const navigate = to => history.push(`/${to}`);

    const init = () => {
        const authentication = ReadObject('authentication')
        if (authentication?.access_token) {
            completeNext()
        }
    }

    const completeNext = () => {
        navigate('dashboard')
    }

    useEffect(() => {
        init()
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <ThemedComponent>
                <Content>
                    <ColContainer>
                        {login ? null : <StyledCol md={{ size: 6 }} className="nopaddings">
                            <SideBackgroundImageContainer>
                                <ImageBackground >
                                    <AppLogo />
                                </ImageBackground>
                            </SideBackgroundImageContainer>
                        </StyledCol>}
                        <StyledColRight md={{ size: login ? 12 : 6 }} className="nopaddings">
                            <FormContent>
                                {children}
                            </FormContent>
                        </StyledColRight>
                    </ColContainer>

                </Content>
            </ThemedComponent>
        </>
    );
}