import styled from 'styled-components'

export const Content = styled.div.attrs({
})`          
    overflow:hidden;
    margin: 0 auto;
`;

export const ContentBody = styled.div.attrs({
})`          
    min-height: 47vh;
`;

export const ContentAnimation = styled.div.attrs({
})`          
    padding: 160px 0 160px;
`;
