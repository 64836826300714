import React from 'react'
import { IconFlag, IconsContainer, Languages, LanguagesContainer, LanguagesTitle } from './styled'
import useI18n from 'hooks/useI18n'


export default function LanguagesFlag() {
  
  const { t } = useI18n(false)

  const flags = [
    '/icons/english.svg',
    '/icons/french.svg',
    '/icons/german.svg',
    '/icons/italian.svg',
    '/icons/spanish.svg',
    '/icons/portugueseFlag.svg',
  ]
  return (
    <>
      <LanguagesContainer>
        <Languages>
          <LanguagesTitle>
            { t("landpage_flags_title") }
          </LanguagesTitle>
          <IconsContainer>
            {
              flags.map((flag, index) => (
                <IconFlag key={index} icon={flag} />
              ))
            }
          </IconsContainer>
        </Languages>
      </LanguagesContainer>
    </>
  )
}
