import React, { useContext, useRef, useState } from "react";
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import PinInput from 'react-pin-input';

import {
    FormSpacer,
    RegisterForgot,
    FormContent,
    LoginContent,
    ImageBackground,
    CloseIcon,
    FormBorder,
    RecaptchaEmbededContainer,
    QrContainer
} from './styled'

import Button from "components/Form/Button";
import Input from 'components/Form/Input'

import ContainerUnauthenticated from "containers/Unauthenticated";
import { CoreContext } from "context/CoreContext";
import { exposeMobdictError } from "utils";
import { Icon, Text, Title } from "ui/styled";
import { theme } from "ui/theme-color";
import { LoginUser, LoginUserGoogle, LoginUserKey, GenerateQrCode, ValidateUserEmail, ValidateApple, LoginApple, LoginFacebook } from "services/mobdict";

import { useGoogleLogin } from '@react-oauth/google';
import useI18n from "hooks/useI18n";
import { fetchGoogleUser, GetLoginCredentials } from "services/api";

import QRCode from "react-qr-code";
import { SaveObject } from "services/storage";
import AppleLogin from "react-apple-login";
import { Turnstile } from "@marsidev/react-turnstile";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props' 
import { parsePassword } from "utils/parsers";


export default function Login() {
    const history = useHistory();
    const navigate = to => history.push(`/${to}`);
    
    const [secureCode, setSecureCode] = useState(null)

    const [loading, setLoading] = useState(false)
    const [loadingCode, setLoadingCode] = useState(false)
    const [loadingSocial, setLoadingSocial] = useState(false)
    const { setUser, setLastKeep, language } = useContext(CoreContext)
    const [checked, setChecked] = useState(false)
        
    const [refreshingCaptcha, setRefreshingCaptcha ] = useState(false)

    const [form, setForm] = useState({})
    const formValue = ref => { return form?.[ref] ? form?.[ref] : ''; }
    const changeForm = (value, ref) => { setForm({ ...form, [ref]: value }); }

    const [validated, setValidated] = useState(false)

    const [appleClientId, setAppleCientId] = useState("");
    const [appleRedirectUrl, setAppleRedirectUrl] = useState("");
    const [appId, setAppId] = useState("");

    const {t} = useI18n()

    const recaptchaKey = `0x4AAAAAAAyTo2YmkKY_AzxT`

    const valid = (verbose = false) => {

        if (!formValue('identifier') || !formValue('identifier').length) {
            if (verbose) { toast.error(`${ t('lg-error-pc-token') }: ${ t('lg-email') }`); }
            return false;
        }

        if (!formValue('password') || !formValue('password').length) {
            if (verbose) { toast.error(`${ t('lg-error-pc-token') }: ${ t('lg-senha') }`); }
            return false;
        }

        if (!validated) {
            if (verbose) { toast.error(`${ t('lg-error-pc-token') }: reCAPTCHA`); }
            return false;
        }

        return true
    }

    const loginGoogle = useGoogleLogin({
        onSuccess: tokenResponse => completeLoginGoole(tokenResponse),
    });

    const login_with_code = async (code) => {
      setLoading(true)

      // console.log("payload", payload)

      const result = await ValidateUserEmail(code)

      setLoading(false)

      if( !exposeMobdictError(result, (!!result?.data?.access_token) )){
          if (result?.data?.mfa !== "True") {
            if (result?.data?.access_token) {
              await SaveObject('authentication', result?.data)
            }
            setLastKeep(new Date().getTime())
          }
          completeLogin(result)
      }
    }

    
    useEffect(() => {
      const fetchData = async () => {
        const params = new URLSearchParams(window.location.search)
        const code = params.get('code')
        
        if (code) {
            console.log(code)
            login_with_code(code)
        }

        const credentials = await GetLoginCredentials("/variaveis");
        setAppleCientId(credentials?.apple_client_id)
        setAppleRedirectUrl(credentials?.apple_redirect_uri)
        setAppId(credentials?.facebook_app_id)
      }
      fetchData();
    }, [])

    const login = async () => {
        if (!valid(true) || loading) { return; }
        setLoading(true)

        var password = parsePassword(formValue('password'))
        const payload = { 
            email: form?.identifier,
            senha: password,
            tipo: "alnp",
            recaptcha: validated,
            url: window.location.origin + '/Login',
            idioma: language
        }

        // console.log("payload", payload)

        const result = await LoginUser(payload)

        // console.log("result", result)
        setLoading(false)

        if( !exposeMobdictError(result, (!!result?.data?.access_token) )){
            if (result?.data?.mfa !== "True") {
              setLastKeep(new Date().getTime())
            }
            completeLogin(result)
        } else {
            setRefreshingCaptcha(true)
            setTimeout(() => { setRefreshingCaptcha(false) ;}, 1)
        }
    }

    const completeLogin = async (result) => {
        // if (result?.user) { setUser(result.user) }
        if(result?.data?.mfa === "True" ){
            setSecureCode( result?.data )

            return;
        }
        if (result?.data?.access_token) {
          await SaveObject('authentication', result?.data)
        }
        navigate('dashboard')
    }

    const loginMfa = async () => {
        if ( loading ) { return; }
        setLoading(true)
        
        const result = await LoginUserKey({
            "chave": secureCode?.chave,
            "token": form?.code,
            "email": form?.identifier,
            "idioma": language
        })
        
        if( !exposeMobdictError(result, Object.keys(result.data).length !== 0 )){
            setLastKeep(new Date().getTime())
            setTimeout(() => navigate('dashboard'), 0);
        }

        setLoading(false)
    }

    const completeLoginGoole = async (token) => {
        // if (loadingSocial) { return; }
        
        setLoadingSocial(true) 

        const decoded = await fetchGoogleUser(token?.access_token); 

        const payload = {
            email: decoded.email,
            tipo: 'alnp',
            senha: decoded.sub,
            idioma: language
        }

        // console.log("payload", payload)

        const result = await LoginUserGoogle(payload)

        // console.log("result", result)
        setLoadingSocial(false)

        if( !exposeMobdictError(result, Object.keys(result.data).length !== 0 )){
            if (result?.data?.access_token) {
              setLastKeep(new Date().getTime())


              completeLogin(result)
            } else {
              SaveObject('google', payload)
              navigate('register')
            }
        }

    }

    const handleCode = async () => {
        if ( loadingCode ) { return; }
        setLoadingCode(true)

        const result = await GenerateQrCode({ email: form?.identifier, idioma: language })

        setLoadingCode(false)
        if( !exposeMobdictError(result, Object.keys(result.data).length !== 0)){
            setSecureCode( result?.data )
        }
    }

    const appleResponse = async (response) => {
      if (response?.authorization) {
   
        const responseData = await ValidateApple({"token": response?.authorization?.id_token, idioma: language});
        if (!exposeMobdictError(responseData, responseData?.data?.length !== 0)) {

          const body = {
            email: responseData.data.email,
            tipo: 'alnp',
            senha: responseData.data.sub,
            idioma: language
          }

          const loginResponse = await LoginApple(body);

          setLoadingSocial(false)

          if( !exposeMobdictError(loginResponse, Object.keys(loginResponse.data).length !== 0 )){
            if (loginResponse?.data?.access_token) {
              setLastKeep(new Date().getTime())


              completeLogin(loginResponse)
            } else {
              SaveObject('google', body)
              navigate('register')
            }
        }
        }
      }
    }

    const handleFacebookCallback = async (response) => {
      if (response?.status === "unknown") {
        console.error('Sorry!', 'Something went wrong with facebook Login.');
        return;
      }
      if (response.email !== undefined) {
        const body = {
          email: response.email,
          tipo: 'alnp',
          senha: response.id,
          idioma: language
        }
        
        const loginResponse = await LoginFacebook(body);

          setLoadingSocial(false)

          if( !exposeMobdictError(loginResponse, Object.keys(loginResponse.data).length !== 0 )){
            if (loginResponse?.data?.access_token) {
              setLastKeep(new Date().getTime())


              completeLogin(loginResponse)
            } else {
              SaveObject('google', body)
              navigate('register')
            }
        }
    }
    }

    const isMobile = window.innerWidth <= 500;
    const containerStyle = {
      display: 'flex',
      justifyContent: 'space-between',
      gap: isMobile ? '4px' : '8px',
      flexWrap: 'wrap',
    };

    const inputStyle = {
      background: theme.palette.colors.backgroundgrey, color: 'black', 
      width: isMobile ? 36 : 46, 
      height: isMobile ? 36 : 46,
      borderRadius: 6, fontSize: 20, fontWeight: "400", fontFamily: "Poppins",
      borderColor: theme.palette.colors.backgroundgrey,
    };

    return (
        <>
            <ContainerUnauthenticated login>
                <LoginContent>
                    <CloseIcon onClick={() => navigate('')} />
                    <ImageBackground src={'/images/background.avif'} />
                    <Icon icon='logo-white' width={180} />
                    {secureCode ? null : <>
                        <FormContent>
                            <Title hasIcon>
                                <Icon icon='logo-mobdict' width={44} nomargin />
                                { t("title") }
                            </Title>
                            <FormSpacer />
                            <Input placeholder={ t('lg-email') } value={formValue('identifier')} onChange={e => changeForm(e.target.value, 'identifier')} />
                            <FormSpacer />
                            <Input placeholder={ t('lg-senha') } type="password" value={formValue('password')} onChange={e => changeForm(e.target.value, 'password')} onSubmitEditing={login} />
                            <RegisterForgot>
                                <Button nospace link primary onClick={() => navigate('forgot')}>{ t('lg-fgp') }</Button>
                            </RegisterForgot>

                            {/* <RecaptchaContainer>
                                <Check size={'large'} label="I'm not a robot" checked={checked} onChange={handleCheck} />
                                <RecaptchaIconContent>
                                    <RecaptchaIcon />
                                    <Span>Privacy - Terms</Span>
                                </RecaptchaIconContent>
                            </RecaptchaContainer> */}

                            {
                                refreshingCaptcha ? null : 
                                <RecaptchaEmbededContainer>
                                  <Turnstile
                                    onSuccess={(token) => {
                                      setValidated(token);
                                    }}
                                    siteKey={recaptchaKey}
                                  />
                                    {/* <ReCAPTCHA
                                        ref={captchaForm}
                                        onChange={setValidated}
                                        sitekey={recaptchaKey}
                                    /> */}
                                </RecaptchaEmbededContainer>
                            }

                            <Button large secondary nospace loading={loading} onClick={login}>{ t('login') }</Button>
                            <FormSpacer />
                            <Button large startIcon={'google'} loadingSocial={loadingSocial} nospace color={'tertiary'} onClick={loginGoogle}>{ t('login-alt') } Google</Button>
                            <FormSpacer />
                            {appleClientId !== "" && appleRedirectUrl !== "" && (
                              <AppleLogin
                              clientId={appleClientId}
                              redirectURI={appleRedirectUrl}
                              usePopup={true}
                              callback={appleResponse} // Catch the response
                              scope="email name"
                              responseMode="query"
                              render={renderProps => (
                                <Button large startIcon={'apple'} loadingSocial={loadingSocial} onClick={renderProps.onClick} nospace color={'black'}>{ t('login-alt') } Apple</Button>
                              )}
                              />
                              )}
                              <FormSpacer />
                              {appId !== "" && (
                              <FacebookLogin 
                              appId={appId}  // we need to get this from facebook developer console by setting the app.
                              autoLoad={false}  
                              fields="name,email,picture"  
                              callback={handleFacebookCallback}
                              render={renderProps => (
                                <Button large startIcon={'facebook'} loadingSocial={loadingSocial} onClick={renderProps.onClick} nospace color={'tertiary'}>{ t('login-alt') } Facebook</Button>
                              )}
                              />)}
                            <FormBorder />
                            <Button large outline secondary nospace colorBlack onClick={() => navigate('register')}>{ t('lg-acc') }</Button>
                        </FormContent>
                    </>}
                    {
                        !secureCode ? null : <>
                            <FormContent>

                                {
                                    !secureCode?.qr_url ? null : <>
                                        <Text center>{ t("login_mfa") }</Text>
                                        <QrContainer>
                                            <QRCode size={200} value={secureCode?.qr_url} />
                                        </QrContainer>
                                    </>
                                }

                                <Title center={!!secureCode?.qr_url} small>{ t("lg-google-auth") }</Title>
                                <FormSpacer />
                                <PinInput
                                    length={6}
                                    initialValue=""
                                    // secret
                                    // secretDelay={10000} 
                                    onChange={(value, index) => changeForm(value, 'code')}
                                    type="numeric"
                                    inputMode="number"
                                    style={containerStyle}
                                    
                                    inputStyle={inputStyle}
                                    inputFocusStyle={{ borderColor: theme.palette.colors.backgroundgrey }}
                                    onComplete={(value, index) => changeForm(value, 'code')}
                                    autoSelect={true}
                                    regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                                />
                                <FormSpacer />
                                <Button small secondary loading={loading} onClick={loginMfa}>
                                    { t('login') }
                                </Button>
                                <Button small secondary loading={loadingCode} onClick={handleCode}>Gerar novo QR Code</Button>
                            </FormContent>
                        </>
                    }
                </LoginContent>
            </ContainerUnauthenticated>
        </>
    );
}