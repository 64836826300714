import styled from "styled-components";

export const Label = styled.div.attrs({
})`
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  color: ${props => props.theme.palette.colors.grey};
`;

export const SayingBody = styled.div.attrs({
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
`;

export const SayingBodyContent = styled.div.attrs({
})`
   display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: fit-content;
  width: 100%;
  gap: 16px;
  margin-top: 50px;
`;

export const ButtonContainer = styled.div.attrs({
})`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  gap: 16px;
`;