import React from 'react';
import { CustomModal, ModalContent, ModalImage, CloseButton, ButtonClose, ModalTitleContainer } from './styled';
import Button from 'components/Form/Button';
import { ButtonContent, Icon, Title } from 'ui/styled';

const ImageModal = ({ isOpen, onRequestClose, currentImage }) => (
  <CustomModal isOpen={isOpen} onClick={onRequestClose} ariaHideApp={false}>
    <ModalContent onClick={(e) => e.stopPropagation()}>
      <ModalTitleContainer>
        <Title>
          Visualize a imagem enviada
        </Title>
        <ButtonClose onClick={onRequestClose}>
          <Icon icon='close-black' />
        </ButtonClose>
      </ModalTitleContainer>
      <ModalImage src={currentImage?.src} alt={currentImage?.alt} />
      <Button secondary onClick={onRequestClose} width='240px'>
        Fechar
      </Button>
    </ModalContent>
  </CustomModal>
);

export default ImageModal;