import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { EmptyMessage, Load, LoadCenter } from 'ui/styled';
import useI18n from "hooks/useI18n";

const StyledTableCell = styled(TableCell)(({ theme, center }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.primary.main,
    textAlign: center ? 'center' : 'left',
    fontSize: 12,
    fontWeight: 600,
    fontFamily: 'Poppins',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontWeight: 400,
    fontFamily: 'Poppins',

  },
}));
const StyledTableCellRow = styled(TableCell)(({ theme, one, itisme }) => ({
  padding: '8px 16px',
  fontWeight: one ? 600 : 500,
  backgroundColor: itisme ? theme.palette.colors.avocado : 'transparent',
  color: itisme ? theme.palette.primary.main : 'inherit',
  fontWeight: itisme ? 800 : '',

}));



export default function BasicTable({ columns, rows, loading }) {
  const { t } = useI18n(false) 
  return (
    <TableContainer component={Paper}
      sx={{
        borderRadius: 0,
        boxShadow: 'none',
      }}
    >
      <Table sx={{ minWidth: 150 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {
              columns?.map((item, key) =>
                <StyledTableCell key={key} align={key === 0 ? "left" : "right"}
                  center={item.position === 'center'}
                >{item.title}</StyledTableCell>
              )
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, rowIndex) => (
            <TableRow

              key={row.name}
              sx={{
                '&:last-child td, &:last-child th': {
                  border: 0,
                }
              }}

            >
              {
                columns?.map((item, key) =>
                  <StyledTableCellRow key={key} align={item.position ? item.position : 'left'}
                    one={rowIndex === 0}
                    itisme={row?.['itisme']}
                  >
                    {item?.['renderCell'] ? item.renderCell({ row }) : row?.[item.ref]}
                  </StyledTableCellRow>
                )
              }
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {
        !loading ? <>
          {rows?.length ? null : <EmptyMessage>{t("dashboard_ranking_empty")}</EmptyMessage>}
        </> :
          <LoadCenter>
            <Load />
          </LoadCenter>
      }
    </TableContainer>
  );
}