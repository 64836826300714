import React, { useContext, useEffect, useMemo, useState } from "react";

import {
    FormSpacer,
    HomeContent,
    CheckoutContentBackground,
    CheckoutContent,
    CheckoutFormContent,
    SummaryContent,
    SummaryItems,
    SummaryItem,
    SummaryItemText,
    CheckContainer,
    Text,
    Link,
    SuccessContent,
    FormText
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import useWindowSize from "utils/useWindowSize";
import { CoreContext } from "context/CoreContext";
import { ButtonContainer, Icon, Title } from "ui/styled";
import Input from "components/Form/Input";
import PhoneNumberInput from "components/Phone";
import Button from "components/Form/Button";
import Select from "components/Form/Select";
import { languages } from "utils/options";
import Check from "components/Form/Check";
import { useHistory } from "react-router-dom";
import { Container } from "reactstrap";

export default function Checkout() {
    const { width } = useWindowSize()
    const { setOpened } = useContext(CoreContext)
    const [loading, setLoading] = useState(false)
    const [termChecked, setTermChecked] = useState(false)
    const [step, setStep] = useState(0)

    const [form, setForm] = useState({})
    const formValue = ref => { return form?.[ref] ? form?.[ref] : ''; }
    const changeForm = (value, ref) => { setForm({ ...form, [ref]: value }); }
    const history = useHistory();
    const navigate = to => history.push(`/${to}`);
    useEffect(() => {
        setOpened(false)
    }, [])

    const steps = useMemo(() => [
        {
            title: 'Digite seus dados',
            text: 'Informe seus dados cadastrais',
            labelNext: 'Continuar',
        },
        {
            title: 'Assinatura realizada com sucesso',
            text: 'Obrigado por assinar nossos serviços.',
            text2: 'Bons estudos!',
            label: 'Fechar',
        },

    ], []);

    const next = () => {
        if (step === steps.length - 1) {
            //save data
            navigate('Login')
            return;
        }
        setStep(step + 1);
    }
    const back = () => {
        if (step === 0) {
            navigate('Login')
            return;
        }
        setStep(step - 1);
    }
    return (
        <>
            <ContainerAuthenticated checkout>
                <HomeContent>
                    {!(step === 0) ? null :

                        <>
                            <CheckoutContentBackground>
                                <CheckoutContent>
                                    <Title hasIcon>
                                        <Icon icon='logo-mobdict' nomargin width={44} />
                                        Confirme a assinatura do plano</Title>
                                    <FormSpacer />
                                    <Select
                                        holder
                                        placeholder="Plano escolhido"
                                        options={[
                                            { id: '1', title: 'Plano - Apaixonado (Anual)' },
                                        ]}
                                    />
                                    <FormSpacer border />
                                    <Title >Dados de pagamento</Title>
                                    <FormSpacer />
                                    <CheckoutFormContent>
                                        <Input placeholder="Nome do titular" id={'name'} value={formValue('name')} onChange={e => changeForm(e.target.value, 'name')} />
                                        <Input placeholder="Numero do cartão" id={'number'} value={formValue('number')} onChange={e => changeForm(e.target.value, 'number')} />
                                        <CheckoutFormContent row>
                                            <Input placeholder="Vencimento" id={'maturity'} value={formValue('maturity')} onChange={e => changeForm(e.target.value, 'maturity')} />
                                            <Input placeholder="CVV" id={'CVV'} value={formValue('CVV')} onChange={e => changeForm(e.target.value, 'CVV')} />
                                        </CheckoutFormContent>
                                    </CheckoutFormContent>

                                </CheckoutContent>
                                <SummaryContent>
                                    <Title hasIcon>
                                        <Icon icon='logo-mobdict' nomargin width={44} />
                                        Resumo da contratação</Title>
                                    <FormSpacer />
                                    <SummaryItems>
                                        <SummaryItem>
                                            <SummaryItemText>
                                                Plano: Apaixonado (Anual)
                                            </SummaryItemText>
                                            <SummaryItemText>
                                                R$ 123,45
                                            </SummaryItemText>
                                        </SummaryItem>
                                        <SummaryItem>
                                            <SummaryItemText>
                                                Vencimento:
                                            </SummaryItemText>
                                            <SummaryItemText>
                                                01/02/24
                                            </SummaryItemText>
                                        </SummaryItem>
                                    </SummaryItems>
                                    <CheckContainer>
                                        <Check success onChange={() => setTermChecked(!termChecked)}
                                            checked={termChecked}
                                            label={
                                                <Text>
                                                    Ao selecionar e continuar, você concorda com nossos <Link>Termos de serviço</Link> e <Link>Política de privacidade e lGPD</Link>
                                                </Text>
                                            }
                                        />
                                    </CheckContainer>
                                    <Button secondary loading={loading} nospace onClick={next} >
                                        Confirmar
                                    </Button>
                                </SummaryContent>
                            </CheckoutContentBackground>
                        </>
                    }
                    {
                        !(step === 1) ? null : <>
                            <SuccessContent>
                                <Icon icon="success" />
                                <FormSpacer />
                                <Container>
                                    <Title center>{steps?.[step]?.title}</Title>
                                    <FormText center>{steps?.[step]?.text}</FormText>
                                    <FormText center>{steps?.[step]?.text2}</FormText>
                                    <FormSpacer />

                                    <FormSpacer border />
                                    <ButtonContainer center>
                                        <Button width='50%' secondary nospace loading={loading} onClick={next} >{steps?.[step]?.label}</Button>
                                    </ButtonContainer>
                                </Container>
                            </SuccessContent>
                        </>
                    }
                </HomeContent>
            </ContainerAuthenticated>
        </>
    );
}