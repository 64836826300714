import React, { useContext } from "react";

import { Row, Col, Container } from 'reactstrap';

import CardSelect from 'components/Landpage/Card/Select'

import {
    BannerImage,
    BannerOut,
    BannerTitle,
    Icon,
    MobidictFilled,
    MobidictOutlined
} from "./styled";
import useI18n from "hooks/useI18n";
import { CoreContext } from "context/CoreContext";


export default function Banner() {
    const {t} = useI18n(false)
    const {language} = useContext(CoreContext)

    return (
        <>
            <BannerImage language={language.toLowerCase()}>
            </BannerImage>
        </>
    );
}