import styled from "styled-components";

export const LanguagesContainer = styled.div.attrs({
})`
    background-color: ${props => props.theme.palette.primary.main};
    min-height: 122px;
    width: 100%;
    padding: 0 72px;
    display: flex;
    align-items: center;
    flex-warp: wrap;
    @media (max-width: 991px) {
        padding: 24px;
    }

`;

export const Languages = styled.div.attrs({
})`
    display: flex;
    width: 100%;
    gap: 24px;
    padding: 0 24px;
    align-items: center;
    flex-wrap: wrap;
    @media (max-width: 991px) {
        padding: 0;
    }
    @media screen and (max-width: 510px) {
      padding: 0;
      justify-content: center;
    }
`;

export const LanguagesTitle = styled.div.attrs({
})`
    font-family: Poppins;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
    color: ${props => props.theme.palette.white.main};

`;

export const IconFlag = styled.div.attrs({
})`
    width: 56px;
    height: 42px;
    gap: 0px;
    border-radius: 8px;
    opacity: 0px;
    background-image: url(${props => props.icon});
    background-size: cover;
    background-position: center;
`;

export const IconsContainer = styled.div.attrs({
})`
    display: flex;
    gap: 24px;
    flex-wrap: wrap;

    @media screen and (max-width: 510px) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }
`;