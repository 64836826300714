import React, { useContext, useEffect } from "react";

import {
    ContactContainer,
    HomeContent,
    InfoName
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import HomeBody from "components/Dashboard/HomeBody";
import Ranking from "components/Ranking";
import useWindowSize from "utils/useWindowSize";
import { CoreContext } from "context/CoreContext";
import CommonQuestions from "components/CommonQuestions";
import { Icon, Title } from "ui/styled";
import ContactUs from "components/ContactUs";
import About from "components/About";

export default function Contact() {
    const { width } = useWindowSize()
    const { setOpened } = useContext(CoreContext)

    useEffect(() => {
        if (width <= 991) {
            setOpened(false)
        } else {
            setOpened(true)
        }
    }, [width])

    return (
        <>
            <ContainerAuthenticated>
                <HomeContent>
                    <ContactContainer>
                        <ContactUs />
                        <About />
                    </ContactContainer>
                    <ContactContainer center>
                        <InfoName>
                            TECHNNI SOFTWARE DEVELOPMENT LTDA - CNPJ: 55.008.821/0001-66
                        </InfoName>
                    </ContactContainer>
                </HomeContent>
            </ContainerAuthenticated>
        </>
    );
}