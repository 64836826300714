import styled from 'styled-components'

export const LoginContent = styled.div.attrs({
})`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    background: ${props => props.theme.palette.primary.main};
    min-height: 100vh;
    position: relative;
    @media(max-width: 767px){
        padding: 0 24px 0;
    }
`;

export const ImageBackground = styled.img.attrs({
})`
    width: 100%;
    height: 100%;
    position: absolute;
    margin-top: 21px;
    z-index: 0;
    max-width: 1477px;
    @media(max-width: 991px){
        display: none;
    }
`;

export const FormContent = styled.div.attrs({
})`
    background: ${props => props.theme.palette.colors.white};
    padding: 32px 40px 32px 40px;
    width: 436px;
    z-index: 1;
    border-radius: 8px;
    @media(max-width: 500px){
        padding: 40px 24px 40px;
        width: 100%;
    }

`;



export const FormTitle = styled.div.attrs({
})`           
    color: ${props => props.theme.palette.primary.main}; 
    font-weight: bold;
    font-size: 20px; 


`;
export const FormText = styled.div.attrs({
})`           
    font-size: 15px; 
    color: ${props => props.theme.palette.colors.grey};
    margin-bottom: 30px;
`;

export const FormSpacer = styled.div.attrs({
})`           
    margin-top: 24px;
`;
export const FormBorder = styled.div.attrs({
})`           
    margin: 24px 0;
    border-bottom: 1px solid ${props => props.theme.palette.colors.backgroundgrey};
`;

export const RegisterForgot = styled.div.attrs({
})`           
    margin-top: 32px;
    font-size: 15px; 
    color: ${props => props.theme.palette.colors.grey};
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 24px;
`;
export const RegisterCall = styled.div.attrs({
})`           
    margin: 30px 0 0px;
    color: ${props => props.theme.palette.colors.grey};
    font-size: 15;
    font-weight: bold;
`;


export const CloseIcon = styled.img.attrs({
    src: '/icons/close.svg'
})`
    width: 56px;
    height: 56px;
    position: absolute;
    top: 40px;
    right: 40px;
    z-index: 1;
    &:hover{
        filter: brightness(0.8);
        cursor: pointer;

    }

    @media(max-width: 500px){
        top: 24px;
        right: 24px;
        width: 32px;
    }
`;

export const RecaptchaContainer = styled.div.attrs({
})`
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: ${props => props.theme.palette.colors.almostwhite};
    border: 1px solid ${props => props.theme.palette.colors.backgroundgrey};
    border-radius: 4px;
    box-shadow: 0 2px 4px ${props => props.theme.palette.colors.shadow};
    width: 100%; 
    justify-content: space-between;
    margin-bottom: 24px;
`;

export const RecaptchaEmbededContainer = styled.div.attrs({
})`
    display: flex;
    justify-content: center;
    padding: 0 0 20px 0;
`

export const Span = styled.span.attrs({
})`
`;

export const RecaptchaIconContent = styled.div.attrs({
})`
    display: flex;
    flex-direction: column;
    font-size: 8px;
    font-weight: 500;
    line-height: 9.38px;
    text-align: center;
    align-items: center;
    gap: 4px;
    color: ${props => props.theme.palette.colors.lightgrey};

`;

export const RecaptchaIcon = styled.img.attrs({
    src: '/icons/recaptcha.svg'
})`
    width: 47px;
    height: 44px;
`;


export const QrContainer = styled.div.attrs({
})`
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

