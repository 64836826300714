import styled from "styled-components";

export const RankingContainer = styled.div.attrs({
})`
  background: ${props => props.theme.palette.colors.white};
  border-radius: 24px;
  max-width: 396px;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 16px;
  ${props => !props.isRanking ? '' : `
      max-width: 100%;
    
    `}
  @media (max-width: 830px) {
    max-width: 100%;
  }
`;
