import styled from 'styled-components';

export const Container = styled.div.attrs({
})`
  padding: 16px;
  background-color: ${props => props.theme.palette.colors.backgroundgrey};
  border-radius: 8px;
  display: flex;
  gap: 16px;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ProgressContainer = styled.div.attrs({
})`
  display: flex;
  align-items: center;
  width: 100%;

`;

export const ProgressText = styled.div.attrs({
})`
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  flex-shrink: 0;
  color: ${props => props.theme.palette.primary.main};
`;

export const ProgressBarBackground = styled.div.attrs({
})`
  height: 28px;
  background-color: ${props => props.theme.palette.colors.lightgrey};
  border-radius: 30px;
  overflow: hidden;
  margin-right: 8px;
  flex: 1;
`;

export const ProgressBarForeground = styled.div.attrs({
})`
  height: 100%;
  border-radius: 30px;
  transition: width 0.3s ease; 
  width: ${props => props.progress}%;
  background-color: ${props => props.theme.palette.colors.avocado};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
`;

export const PercentageText = styled.span.attrs({
})`
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
`;
