import React, { useContext, useEffect, useState } from "react";

import {
    TutorialContainer,
    HomeContent,
    VideoContainer,
    TitleContainer,
    TutorialContent
} from "./styled";

import ContainerAuthenticated from "containers/Authenticated";
import useWindowSize from "utils/useWindowSize";
import { CoreContext } from "context/CoreContext";
import VideoPlayer from "components/VideoPlayer";
import useI18n from "hooks/useI18n";
import { ReadVideos } from "services/mobdict";
import { exposeMobdictError } from "utils";
import { ReadObject, SaveObject } from "services/storage";
import { toast } from "react-toastify";
import { Icon, Title } from "ui/styled";

export default function Contact() {
    const { width } = useWindowSize()
    const { setOpened, language } = useContext(CoreContext)
    const { t } = useI18n(false)

    const [videos, setVideos] = useState([])

    const fetchVideos = async () => {
        const videos = ReadObject('videos')
        if (videos) {
            setVideos(videos)
            return
        }

        const response = await ReadVideos(language)
        
        if (!exposeMobdictError(response, Object.keys(response).length !== 0)) {
            SaveObject('videos', response.data)
            setVideos(response.data)
        } else {
            toast.error(response.message)
        }
      }

    useEffect(() => {
        const fetchData = async () => {
            await fetchVideos()
        }
        fetchData()
        if (width <= 991) {
            setOpened(false)
        } else {
            setOpened(true)
        }
    }, [width])

    return (
        <>
            <ContainerAuthenticated>
                <HomeContent>
                  <TutorialContent>
                      <Title hasIcon>
                        <Icon icon='logo-mobdict' width={44} nomargin />
                        { t("dashboard_side_tutorial") }
                      </Title>
                      <TutorialContainer>
                        <VideoContainer>
                          {videos.map((video, index) => (
                              <>
                                <VideoPlayer key={index} url={video.url} width={'350px'} dash={true}/>
                                <TitleContainer>{t(`${video.titulo}`)}</TitleContainer>
                              </>
                          ))}
                          {/* <VideoPlayer url={"/videos/landpage_english.mp4"} width={'350px'} dash={true}/>
                          <TitleContainer>{t("dashboard_tutorial_video_title")}</TitleContainer> */}
                        </VideoContainer>
                      </TutorialContainer>
                  </TutorialContent>
                </HomeContent>
            </ContainerAuthenticated>
        </>
    );
}