

export const static_translation = {
    "lg-acc": "Cadastre-se",
    "login": "Entrar",
    "landpage_banner_balloon": "Aprimore um novo idioma!",
    "landpage_flags_title": "Aprimore um idioma com o Mobdict®",
    
    "landpage_how_works_title": "Nossa maior satisfação é ajudar no aprendizado de um novo idioma ❤️",
    "landpage_how_works_text": "Somos apaixonados por idiomas e educação. Nosso objetivo é tornar o aprendizado acessível e divertido para todos, utilizando uma abordagem inovadora baseada em ditados.",
    "landpage_how_works_action": "Quero aprender mais, vamos começar",
    
    "landpage_learn_title": "Aprenda muito mais 😎",
    "landpage_learn_text": "Nosso método de ensino se concentra em praticar idiomas por meio de ditados, uma técnica que combina compreensão auditiva, leitura e prática escrita para melhorar suas habilidades linguísticas de forma natural e eficaz.",
    "landpage_learn_action": "Começar agora",
    
    "landpage_write_title": "Escreva à Mão e Aprenda mais com Mobdict®",
    "landpage_write_text": "Escrever à mão pode melhorar a retenção e a compreensão do vocabulário, pois envolve processos cognitivos mais profundos. Envie seus manuscritos para o Mobdict® usando o app Mobdict® Image Sender.",
    
    "landpage_free_title": "Faça um teste gratuito 🥹",
    "landpage_free_text": "Você pode praticar o idioma que está estudando, no nível que se encontra, testando o Mobdict® gratuitamente por uma semana e fazer até 7 ditados para ver seu vocabulário aumentar a cada desafio.",
    "landpage_free_action": "Quero experimentar",

    "landpage_more_title": "Aprenda da melhor forma  🎬",
    "landpage_more_text": "Assista nosso vídeo para entender como a aprendizagem de idiomas utilizando ditados pode beneficiar você.",
    
    "faq_title": "FAQ - Perguntas frequentes",
    "faq_question_1": "Quem pode participar do curso?",
    "faq_answer_1": "Para saber quem pode participar de um curso, você precisa verificar os requisitos específicos estabelecidos pela instituição ou pelos responsáveis pelo curso",
    "faq_question_2": "Nosso curso é ideal para iniciantes e para aqueles que desejam aprimorar suas habilidades linguísticas.",
    "faq_answer_2": "Nosso curso é ideal para iniciantes e para aqueles que desejam aprimorar suas habilidades linguísticas. Portanto, qualquer pessoa que esteja começando ou que queira desenvolver ainda mais suas competências na língua pode participar.",
    "faq_question_3": "Como funciona o método de aprendizagem por ditados?",
    "faq_answer_3": "O método de aprendizagem por ditados é uma técnica que ajuda a melhorar a habilidade de escuta, a ortografia e a compreensão textual.",
    "faq_question_4": "Qual é a duração do curso?",
    "faq_answer_4": "A duração do curso pode variar dependendo de vários fatores, como a instituição que o oferece, a carga horária total e a frequência das aulas",
    
    "footer_talk_us": "Fale conosco",
    "footer_support": "Suporte",
    "footer_all_rights": "Direitos reservados",
    
    "lg-google-auth": "Insira o código de autenticação do google authenticator",

    "dashboard_home_see_plans": "Ver planos",
    "dashboard_home_dicts_done": "realizados",
    "dashboard_home_dicts_plan": "Ditados contratados",
    "dashboard_home_dicts_test": "Período de teste",
    "dashboard_home_dicts_days": "dias restantes",
    "dashboard_home_dicts_test_done": "Período de teste concluído",
    "dashboard_home_dicts_dict": "Ditados",
    
    "dashboard_home_learning": "Estou aprendendo",
    "dashboard_home_level": "Nível",
    "dashboard_home_cta": "Vamos começar o ditado",
    
    "dashboard_plan_title": "Assine um dos nossos planos",
    "dashboard_plan_subtitle": "cobrados anualmente",
    "dashboard_plan_pop": "Mais popular",
    "dashboard_plan_1": "Plano Básico",
    "dashboard_plan_2": "Plano Intermediário",
    "dashboard_plan_3": "Plano Avançado",
    "dashboard_plan_dict": "ditados",
    "dashboard_plan_action": "Assine",
    "dashboard_plan_currency": "R$",
    
    "dashboard_talkus_title": "Fale conosco",
    "dashboard_talkus_email": "Email",
    "dashboard_talkus_support_email": "Suporte técnico",
    "dashboard_talkus_support_address": "Endereço",
    "dashboard_talkus_support_social": "Redes sociais",
    "dashboard_talkus_about_title": "Quem somos",
    "dashboard_talkus_about_text": "No Mobdict®, transformar seu aprendizado de idiomas é mais fácil e divertido com ditados! Estas pequenas pérolas de sabedoria oferecem uma forma única de absorver a cultura e a linguagem. Ao aprender um novo idioma, descubra ditados que refletem o modo de pensar e viver dos falantes nativos. Cada expressão traz uma lição valiosa e contexto cultural, ajudando você a dominar nuances e a soar mais natural.",
    
    "dashboard_home_ranking_title": "Ranking",
    "dashboard_home_ranking_position": "Pos.",
    "dashboard_home_ranking_name": "Nome",
    "dashboard_home_ranking_points": "Pontuação",
    "dashboard_home_ranking_see_more": "Ver mais",

    "dashboard_side_home": "Home",
    "dashboard_side_ranking": "Ranking",
    "dashboard_side_buy": "Comprar créditos",
    "dashboard_side_account": "Minha conta",
    "dashboard_side_settings": "Configurações",
    "dashboard_side_faq": "FAQ",
    "dashboard_side_talk_us": "Fale conosco",
    "dashboard_side_exit": "Sair",
    
    "dashboard_form_password_validation": "Nova senha e confirmação da nova senha não são iguais",

    "dashboard_form_name": "Nome completo",
    "dashboard_form_nickname": "Escolha seu username (Apelido)",
    "dashboard_form_email": "Email",
    "dashboard_form_phone": "Celular",
    "dashboard_form_password": "Senha",
    "dashboard_form_level": "Nível",
    "dashboard_form_confirm_password": "Repita a senha",

    "dashboard_form_account_title": "Minha conta",
    "dashboard_form_password_title": "Alterar senha",
    
    "dashboard_form_cancel": "Cancelar",
    "dashboard_form_next": "Continuar",
    "dashboard_form_save": "Salvar",
    "dashboard_form_subgender": "Selecione os subgêneros",
    
    "dashboard_settings_title": "Configurações",
    "dashboard_settings_2fa": "Habilitar Autenticação de Dois Fatores",
    "dashboard_settings_adjust_pause": "Ajustar pausa no ditado",
    "dashboard_settings_pause": "Pausa",
    "dashboard_settings_subgender": "Redefinir subgêneros favoritos (min. 3):",
    "dashboard_settings_define": "Definir",
    "dashboard_settings_default_lang": "Idioma padrão",
    "dashboard_settings_native_lang": "Idioma nativo",
    "dashboard_settings_learn_lang": "Idioma aprendizado",
    "dashboard_settings_recurrency": "Assinatura recorrente",
    
    
    "dashboard_dict_congrats": "Parabéns!",
    "dashboard_dict_added_increase": "Você adicionou mais",
    "dashboard_dict_added_words": "palavras ao seu vocabulário.",
    "dashboard_dict_restart": "Iniciar novo ditado",
    "dashboard_dict_back_homepage": "Voltar à página inicial",
    

    "dashboard_dict_wrong_words": "Palavras estão incorretas",
    "dashboard_dict_wrong_omit": "Palavras omitidas",
    "dashboard_dict_wrong_finish": "Finalizar",
    "dashboard_dict_wrong_challange": "Desafio",
    "dashboard_dict_wrong_reinforcement": "Fazer ditado de reforço",
    
    "dashboard_dict_translate": "Tradução",
    "dashboard_dict_see_translate": "Ver tradução",
    
    "dashboard_dict_writing_title": "Digite o texto",
    "dashboard_dict_writing_handed": "Escrever a mão",
    "dashboard_dict_writing_special": "Caracteres especiais",
    
    "dashboard_dict_writing_step_1": "1. Baixe o app Mobdict Image Sender na App Store ou Play Store e faça login.",
    "dashboard_dict_writing_step_2": "2. Escreva o ditado que você ouviu em um papel e tire uma foto.",
    "dashboard_dict_writing_step_3": "3. Envie a imagem pelo aplicativo e clique no botão ao lado para finalizar.",
    "dashboard_dict_writing_step_action": "Já enviei",
    "dashboard_dict_writing_step_confirm": "Já conferi, Corrigir",
    
    "dashboard_dict_writing_progress": "Seu progresso",
    "dashboard_dict_writing_meta": "Meta",
    "dashboard_dict_writing_words": "palavras",
    
    "dashboard_register_title": "Digite seus dados",
    "dashboard_register_subtitle": "Informe seus dados cadastrais",
    
    "dashboard_password_title": "Crie sua senha",
    "dashboard_register_level": "Selecione o idioma e nível",
    "dashboard_register_subgender": "Selecione o subgênero",
    
    "dashboard_register_terms_1": "Ao selecionar e continuar, você concorda com nossos",
    "dashboard_register_terms_2": "Termos de serviço",
    "dashboard_register_and": "e",
    "dashboard_register_terms_3": "Política de privacidade",
    
    "dashboard_register_validation_not_match": "Nova senha e confirmação da nova senha não são iguais",
    "dashboard_register_validation_accept_terms": "Aceite os termos",
    "dashboard_register_validation_email": "Informe o email",
    "dashboard_register_validation_password": "Informe a nova senha",
    "dashboard_register_validation_password_confirmation": "Informe a confirmação da nova senha",
    "dashboard_register_successfull": "Conta criada com sucesso",
    
    "dashboard_register_step1_title": "Digite seus dados",
    "dashboard_register_step1_text": "Informe seus dados cadastrais",
    "dashboard_register_step1_action": "Continuar",
    
    "dashboard_register_step2_title": "Cadastro finalizado com sucesso",
    "dashboard_register_step2_text": "Obrigado por se cadastrar.",
    "dashboard_register_step2_action": "Fechar",
    "dashboard_register_step2_back": "Voltar",

    "dashboard_forgot_step1_title": "Esqueceu sua senha?",
    "dashboard_forgot_step1_text": "Digite seu e-mail cadastrado para recuperar sua senha",
    "dashboard_forgot_step2_title": "Verifique seu email",
    "dashboard_forgot_step2_text": "Acesse o link de recuperação que foi enviado para seu email cadastrado",
    
    "dashboard_createpassword_step1_title": "Nova senha",
    "dashboard_createpassword_step1_text": "Digite uma nova senha para sua conta",
    "dashboard_createpassword_step2_title": "Nova senha criada com sucesso",
    "dashboard_createpassword_step2_text": "Agora você já pode fazer seu login com sua nova senha",
    
    "login_mfa": "Com o aplicativo Google Authenticator, ou outro aplicativo de autenticação, escaneie o QRCode abaixo para gerar seu token",
    
    
    "register_validation_subgender": "Selecione pelo menos 3 subgêneros para continuar",
    "dict_word_bellow": "Utilize as palavras abaixo para completar o texto",
    "dict_reinforcement": "Ditado de reforço",
    "dict_a_newdict": "Iniciar um novo ditado",
    "dict_by": "Ditado por",
    "dict_finish": "Finalizar",
    "dict_next": "Continuar",
    "dict_correct": "Corrigir",
    
    "Autoajuda": "Autoajuda",
    "Biografia": "Biografia",
    "Comédia": "Comédia",
    "Conto": "Conto",
    "Crônica": "Crônica",
    "Ecologia": "Ecologia",
    "Epopeia": "Epopeia",
    "Fábula": "Fábula",
    "Farsa": "Farsa",
    "Ficção": "Ficção",
    "Infantojuvenil": "Infantojuvenil",
    "Novela": "Novela",
    "Poesia": "Poesia",
    "Romance": "Romance",
    "Suspense": "Suspense",
    "Tragédia": "Tragédia",
    "True Crime": "True Crime",
        
}
