import React, { useContext} from "react";

import DashboardSide from "../Side";
import {
    DashboardHeaderContainer,
    DashboardHeaderAction,
    DashboardHeaderActionIcon,
    HeaderContent,
} from "./styled";

import Button from "components/Form/Button";

import SectionHeader from "components/SectionHeader";
import { CoreContext } from "context/CoreContext";
import InfoStudying from "components/InfoStudying";

import { Icon } from "ui/styled";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useI18n from "hooks/useI18n";

export default function DashboardHeader({ close, saying, checkout, logged }) {
    const history = useHistory();
    const navigate = to => history.push(`/${to}`);
    const { t } = useI18n(false)

    const { opened, setOpened } = useContext(CoreContext)

    return (
        <>
            <HeaderContent logged={logged}>
                {logged && <Button nospace outline secondary colorBlack width={'200px'} onClick={() => navigate('dashboard')}>
                              <Icon icon="chevron-left" />
                              {t("dashboard_side_home")}
                            </Button>}
                <DashboardSide opened={opened} setOpened={setOpened} />
                <DashboardHeaderContainer saying={saying} checkout={checkout}>
                    <DashboardHeaderAction >
                        {saying || checkout || logged ? null : <DashboardHeaderActionIcon src={`/icons/menu.svg`} alt="menu-icon" onClick={() => setOpened(true)} />}
                        {!checkout ? null : <Icon icon={'logo'} />}
                        {saying ? <InfoStudying /> : null}
                    </DashboardHeaderAction>
                    {checkout ? null : <SectionHeader close={close} />}
                    {/*  */}
                </DashboardHeaderContainer>
            </HeaderContent>
        </>
    );
}