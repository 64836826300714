import React, { useContext, useEffect, useMemo } from 'react'
import { ButtonClose, ModalBody, ModalContainer, ModalContent, ModalLabel, ModalTitleContainer, Overlay } from './styled'
import { Icon, Title } from 'ui/styled'
import PlansCard from 'components/PlansCard'
import { CoreContext } from 'context/CoreContext'
import { useHistory } from 'react-router-dom'
import { parseCurrency } from 'utils/parsers'
import useI18n from 'hooks/useI18n'
import { ReadPlans } from 'services/mobdict'
import { exposeMobdictError } from 'utils'

export default function PlansLp() {

  const { t } = useI18n(false)

  const { setModal, setPlans, plans, language } = useContext(CoreContext)

  const testDrivePlan = {
    "plan": `${t("dashboard_plan_name")}`,
    "dictations": `5 ${ t("dashboard_plan_dict") }`,
    "price": `${t("dashboard_plan_currency")} 0,00`,
    "action": `${t("dashboard_plan_free_action")}`,
    free: true,
    famous: false,
    lp: true,
  }

  const fetchPlans = async () => {
    const result = await ReadPlans(language)
    if(!exposeMobdictError(result, !!( result?.data?.planos ) )){
        // console.log("PLANS", result?.data?.planos)
        setPlans(result?.data?.planos)
        return result?.data?.planos;
    }
  }

  useEffect(() => {
    fetchPlans()
  }, [language])

  var parsedPlans = useMemo(() => ([
    ...(plans||[])?.map((m,k) => ({
        ...m,
        "plan": `${ m?.[language] }`,
        // "dictations": "5 ditados ou 7 dias",
        "dictations": `${m?.qtd_ditados} ${ t("dashboard_plan_dict") }`,
        "price": `${parseCurrency(m?.valor||'-', t("dashboard_plan_currency"))}`,
        // "action": "Testar",
        "action": `${ t("dashboard_plan_action") }`,
        free: false,
        lp: true,
        famous: k===1,
      }))?.filter(f => f?.status === "ativo")
  ]), [plans])

  parsedPlans = [testDrivePlan, ...parsedPlans]

  return (
    <>
        <ModalContent>
          <ModalTitleContainer>
            <Title hasIcon>
              <Icon icon='logo-mobdict' width={44} nomargin />
                { t("dashboard_plan_title") }
              <ModalLabel>
                ({ t("dashboard_plan_subtitle") })
              </ModalLabel>
            </Title>
          </ModalTitleContainer>
          <ModalBody text={parsedPlans.length === 1}>
            {
              parsedPlans.map((plan, index) => (
                <PlansCard
                  key={index}
                  planIndex={index}
                  { ...plan }
                />
              ))
            }

          </ModalBody>
        </ModalContent>
    </>
  )
}
