export const static_translation = {
    "lg-acc": "Regístrate",
    "login": "Iniciar sesión",

    "landpage_banner_balloon": "¡Mejora un nuevo idioma!",
    "landpage_flags_title": "Mejora un idioma con Mobdict®",
    
    "landpage_how_works_title": "Nuestra mayor satisfacción es ayudarte a aprender un nuevo idioma ❤️",
    "landpage_how_works_text": "Nos apasionan los idiomas y la educación. Nuestro objetivo es hacer que el aprendizaje sea accesible y divertido para todos, utilizando un enfoque innovador basado en dictados.",
    "landpage_how_works_action": "Quiero aprender más, empecemos",
    
    "landpage_learn_title": "Aprende mucho más 😎",
    "landpage_learn_text": "Nuestro método de enseñanza se centra en practicar idiomas a través de dictados, una técnica que combina comprensión auditiva, lectura y práctica de la escritura para mejorar tus habilidades lingüísticas de manera natural y efectiva.",
    "landpage_learn_action": "Comenzar ahora",
    
    "landpage_write_title": "Escribe a mano y aprende más con Mobdict®",
    "landpage_write_text": "Escribir a mano puede mejorar la retención y comprensión del vocabulario, ya que implica procesos cognitivos más profundos. Envía tus manuscritos a Mobdict® usando la aplicación Mobdict® Image Sender.",
    
    "landpage_free_title": "Prueba gratuita 🥹",
    "landpage_free_text": "Puedes practicar el idioma que estás estudiando, en tu nivel actual, probando Mobdict® gratuitamente durante una semana y hacer hasta 7 dictados para ver cómo aumenta tu vocabulario con cada desafío.",
    "landpage_free_action": "Quiero probar",
    
    "landpage_more_title": "Aprende de la mejor manera 🎬",
    "landpage_more_text": "Mira nuestro video para entender cómo el aprendizaje de idiomas mediante dictados puede beneficiarte.",
    
    "faq_title": "FAQ - Preguntas frecuentes",
    "faq_question_1": "¿Quién puede participar en el curso?",
    "faq_answer_1": "Para saber quién puede participar en un curso, debes verificar los requisitos específicos establecidos por la institución o los responsables del curso.",
    "faq_question_2": "Nuestro curso es ideal para principiantes y para aquellos que desean mejorar sus habilidades lingüísticas.",
    "faq_answer_2": "Nuestro curso es ideal para principiantes y para aquellos que desean mejorar sus habilidades lingüísticas. Por lo tanto, cualquier persona que esté comenzando o que quiera desarrollar aún más sus competencias en el idioma puede participar.",
    "faq_question_3": "¿Cómo funciona el método de aprendizaje por dictados?",
    "faq_answer_3": "El método de aprendizaje por dictados es una técnica que ayuda a mejorar las habilidades auditivas, la ortografía y la comprensión de textos.",
    "faq_question_4": "¿Cuál es la duración del curso?",
    "faq_answer_4": "La duración del curso puede variar según varios factores, como la institución que lo ofrece, el número total de horas del curso y la frecuencia de las clases.",
    
    "footer_talk_us": "Contáctanos",
    "footer_support": "Soporte",
    "footer_all_rights": "Todos los derechos reservados",
    
    "lg-google-auth": "Introduce el código de autenticación de Google Authenticator",

    "dashboard_home_see_plans": "Ver planes",
    "dashboard_home_dicts_done": "realizados",
    "dashboard_home_dicts_plan": "Dictados comprados",
    "dashboard_home_dicts_test": "Período de prueba",
    "dashboard_home_dicts_days": "días restantes",
    "dashboard_home_dicts_test_done": "Período de prueba completado",
    "dashboard_home_dicts_dict": "Dictados",
    
    "dashboard_home_learning": "Estoy aprendiendo",
    "dashboard_home_level": "Nivel",
    "dashboard_home_cta": "Comencemos el dictado",
    
    "dashboard_plan_title": "Suscríbete a uno de nuestros planes",
    "dashboard_plan_subtitle": "facturado anualmente",
    "dashboard_plan_pop": "Más popular",
    "dashboard_plan_1": "Plan Básico",
    "dashboard_plan_2": "Plan Intermedio",
    "dashboard_plan_3": "Plan Avanzado",
    "dashboard_plan_dict": "dichos",
    "dashboard_plan_action": "Suscribirse",
    "dashboard_plan_currency": "€",
    
    "dashboard_talkus_title": "Contáctanos",
    "dashboard_talkus_email": "Correo electrónico",
    "dashboard_talkus_support_email": "Soporte Técnico",
    "dashboard_talkus_support_address": "Dirección",
    "dashboard_talkus_support_social": "Redes sociales",
    "dashboard_talkus_about_title": "Sobre nosotros",
    "dashboard_talkus_about_text": "En Mobdict®, transformar tu aprendizaje de idiomas es más fácil y divertido con dichos. Estas pequeñas perlas de sabiduría ofrecen una forma única de absorber la cultura y el idioma. Al aprender un nuevo idioma, descubre dichos que reflejan la forma de pensar y vivir de los hablantes nativos. Cada expresión trae valiosas lecciones y contexto cultural, ayudándote a dominar las sutilezas y a sonar más natural.",
    
    "dashboard_home_ranking_title": "Clasificación",
    "dashboard_home_ranking_position": "Pos.",
    "dashboard_home_ranking_name": "Nombre",
    "dashboard_home_ranking_points": "Puntos",
    "dashboard_home_ranking_see_more": "Ver más",

    "dashboard_side_home": "Inicio",
    "dashboard_side_ranking": "Clasificación",
    "dashboard_side_buy": "Comprar créditos",
    "dashboard_side_account": "Mi cuenta",
    "dashboard_side_settings": "Configuraciones",
    "dashboard_side_faq": "FAQ",
    "dashboard_side_talk_us": "Contáctanos",
    "dashboard_side_exit": "Salir",




    


    
    "dashboard_form_password_validation": "La nueva contraseña y la confirmación no son iguales",

    "dashboard_form_name": "Nombre completo",
    "dashboard_form_nickname": "Elige tu nombre de usuario (Apodo)",
    "dashboard_form_email": "Correo electrónico",
    "dashboard_form_phone": "Celular",
    "dashboard_form_password": "Contraseña",
    "dashboard_form_level": "Nivel",
    "dashboard_form_confirm_password": "Repite la contraseña",

    "dashboard_form_account_title": "Mi cuenta",
    "dashboard_form_password_title": "Cambiar contraseña",
    
    "dashboard_form_cancel": "Cancelar",
    "dashboard_form_next": "Continuar",
    "dashboard_form_save": "Guardar",
    "dashboard_form_subgender": "Selecciona los subgéneros",
    
    "dashboard_settings_title": "Configuración",
    "dashboard_settings_2fa": "Habilitar autenticación de dos factores",
    "dashboard_settings_adjust_pause": "Ajustar pausa en el dictado",
    "dashboard_settings_pause": "Pausa",
    "dashboard_settings_subgender": "Restablecer subgéneros favoritos (mín. 3):",
    "dashboard_settings_define": "Definir",
    "dashboard_settings_default_lang": "Idioma predeterminado",
    "dashboard_settings_native_lang": "Idioma nativo",
    "dashboard_settings_learn_lang": "Idioma de aprendizaje",
    "dashboard_settings_recurrency": "Suscripción recurrente",
    
    
    "dashboard_dict_congrats": "¡Felicidades!",
    "dashboard_dict_added_increase": "Has añadido más",
    "dashboard_dict_added_words": "palabras a tu vocabulario.",
    "dashboard_dict_restart": "Iniciar nuevo dictado",
    "dashboard_dict_back_homepage": "Volver a la página de inicio",
    

    "dashboard_dict_wrong_words": "Las palabras están incorrectas",
    "dashboard_dict_wrong_omit": "Palabras omitidas",
    "dashboard_dict_wrong_finish": "Finalizar",
    "dashboard_dict_wrong_challange": "Desafío",
    "dashboard_dict_wrong_reinforcement": "Hacer dictado de refuerzo",
    
    "dashboard_dict_translate": "Traducción",
    "dashboard_dict_see_translate": "Ver traducción",
    
    "dashboard_dict_writing_title": "Escribe el texto",
    "dashboard_dict_writing_handed": "Escribir a mano",
    "dashboard_dict_writing_special": "Caracteres especiales",
    
    "dashboard_dict_writing_step_1": "1. Descarga la aplicación Mobdict Image Sender desde la App Store o Play Store e inicia sesión.",
    "dashboard_dict_writing_step_2": "2. Escribe el dictado que escuchaste en un papel y toma una foto.",
    "dashboard_dict_writing_step_3": "3. Envía la imagen a través de la aplicación y haz clic en el botón al lado para finalizar.",
    "dashboard_dict_writing_step_action": "Ya he enviado",
    "dashboard_dict_writing_step_confirm": "Ya he revisado, Corregir",
    
    "dashboard_dict_writing_progress": "Tu progreso",
    "dashboard_dict_writing_meta": "Meta",
    "dashboard_dict_writing_words": "palabras",
    
    "dashboard_register_title": "Introduce tus datos",
    "dashboard_register_subtitle": "Proporciona tus datos personales",
    
    "dashboard_password_title": "Crea tu contraseña",
    "dashboard_register_level": "Selecciona el idioma y nivel",
    "dashboard_register_subgender": "Selecciona el subgénero",
    
    "dashboard_register_terms_1": "Al seleccionar y continuar, aceptas nuestros",
    "dashboard_register_terms_2": "Términos de servicio",
    "dashboard_register_and": "y",
    "dashboard_register_terms_3": "Política de privacidad",
    
    "dashboard_register_validation_not_match": "La nueva contraseña y la confirmación no son iguales",
    "dashboard_register_validation_accept_terms": "Acepta los términos",
    "dashboard_register_validation_email": "Proporciona el correo electrónico",
    "dashboard_register_validation_password": "Proporciona la nueva contraseña",
    "dashboard_register_validation_password_confirmation": "Proporciona la confirmación de la nueva contraseña",
    "dashboard_register_successfull": "Cuenta creada exitosamente",
    
    "dashboard_register_step1_title": "Introduce tus datos",
    "dashboard_register_step1_text": "Proporciona tus datos personales",
    "dashboard_register_step1_action": "Continuar",
    
    "dashboard_register_step2_title": "Registro completado con éxito",
    "dashboard_register_step2_text": "Gracias por registrarte.",
    "dashboard_register_step2_action": "Cerrar",
    "dashboard_register_step2_back": "Volver",

    "dashboard_forgot_step1_title": "¿Olvidaste tu contraseña?",
    "dashboard_forgot_step1_text": "Introduce tu correo electrónico registrado para recuperar tu contraseña",
    "dashboard_forgot_step2_title": "Revisa tu correo electrónico",
    "dashboard_forgot_step2_text": "Accede al enlace de recuperación que fue enviado a tu correo electrónico registrado",
    
    "dashboard_createpassword_step1_title": "Nueva contraseña",
    "dashboard_createpassword_step1_text": "Introduce una nueva contraseña para tu cuenta",
    "dashboard_createpassword_step2_title": "Nueva contraseña creada exitosamente",
    "dashboard_createpassword_step2_text": "Ahora puedes iniciar sesión con tu nueva contraseña",

    "login_mfa": "Usando la aplicación Google Authenticator u otra aplicación de autenticación, escanee el código QR a continuación para generar su token",


    "register_validation_subgender": "Selecciona al menos 3 subgéneros para continuar",
    "dict_word_bellow": "Utiliza las palabras a continuación para completar el texto",
    "dict_reinforcement": "Dictado de refuerzo",
    "dict_a_newdict": "Iniciar un nuevo dictado",
    "dict_by": "Dictado por",
    "dict_finish": "Finalizar",
    "dict_next": "Continuar",
    "dict_correct": "Corregir",
    
    "Autoajuda": "Autoayuda",
    "Biografia": "Biografía",
    "Comédia": "Comedia",
    "Conto": "Cuento",
    "Crônica": "Crónica",
    "Ecologia": "Ecología",
    "Epopeia": "Epopeya",
    "Fábula": "Fábula",
    "Farsa": "Farsa",
    "Ficção": "Ficción",
    "Infantojuvenil": "Literatura infantil y juvenil",
    "Novela": "Novela",
    "Poesia": "Poesía",
    "Romance": "Romance",
    "Suspense": "Suspense",
    "Tragédia": "Tragedia",
    "True Crime": "True Crime"

}
