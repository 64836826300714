import styled from 'styled-components'

export const HomeContent = styled.div.attrs({
})`
    display: flex;
    width: 100%;
    gap: 24px;
    padding: 24px 40px;
    flex-wrap: wrap;
    @media (max-width: 768px) {
        padding: 24px 16px;
    }
`;

export const TutorialContent = styled.div.attrs({
})`
  background: ${props => props.theme.palette.colors.white};
    width: 100%;
    border-radius: 8px;
    padding: 32px 40px;
    @media(max-width: 767px){
        padding: 24px 16px;
    }`;

export const TutorialContainer = styled.div.attrs({
})`
    display: flex;
    gap: 16px;
    justify-content: ${props => !props.center ? ' space-between' : 'center'};
    width: 100%;
    border-radius: 8px;
    background-color: ${props => props.theme.palette.colors.white};
    padding: 16px;
    flex-wrap: wrap;
`;

export const VideoContainer = styled.div.attrs({
})`
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    flex-direction: column;
`;

export const TitleContainer = styled.div.attrs({
})`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 16px 8px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    color: ${props => props.theme.palette.primary.main};
    margin-top: 16px;
    border: 1px solid ${props => props.theme.palette.colors.backgroundgrey};
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    margin: 0;
`;