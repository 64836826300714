import NewSaying from 'components/NewSaying'
import ProgressBar from 'components/ProgessBar'
import Response from 'components/Response'
import Traduction from 'components/Traduction'
import Writing from 'components/Writing'
import { CoreContext } from 'context/CoreContext'
import React, { useContext } from 'react'

export default function SayingBody({ label, writingAction, response, audioDict, audioOriginal, loading, confirmImage }) {
  const { dict, words, reinforcement, toCorrect, challenge } = useContext(CoreContext)

  return (
    <>
      <ProgressBar meta={ words?.meta || 0 } progress={ words?.qtd_certas||0 } progress_status={ (((words?.qtd_certas||0))/(words?.meta||0))*100 } />
      <NewSaying audioDict={audioDict} audioOriginal={audioOriginal} />
      { !response ? null : <Response response={response} challenge={challenge} /> }
      { ((toCorrect && !audioOriginal)||reinforcement) ? null : <Traduction toCorrect={toCorrect} dict={dict} /> }
      <Writing Label={label} action={writingAction} loading={loading} nextable={(toCorrect && !!audioOriginal)} confirmImage={confirmImage} />
    </>
  )
}
