import styled from 'styled-components'
import ReactLoading from 'react-loading';
import Lottie from 'react-lottie';

export const hexToRgb = (hex) => {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',');
    }
    return `255,255,255`
}

export const Touch = styled.div.attrs({
})`         
    cursor: pointer;
    &:hover{
        box-shadow: 0px 1px 3px ${props => props.theme.palette.colors.shadow};
    }
`;

export const Load = styled(ReactLoading).attrs({
    type: 'spin',
    color: '#ffffff',
    height: 20,
    width: 20
})`          
`;

export const Animation = styled(Lottie).attrs(props => ({
    options: {
        loop: true,
        autoplay: true,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        },
        animationData: props.animationData
    },
    width: props.width ? props.width : 320
}))`        
    max-width: 100%;
`;

export const EmptyMessage = styled.div.attrs({
})`         
    padding: 32px; 
    text-align: center;
    font-size: 14px;
    color: ${props => props.theme.palette.colors.black};
`;

export const LoadCenter = styled.div.attrs({
})`         
    width: 20px;
    margin: 32px auto; 
    display: flex;
    justify-content: center;
`;

export const DecoratedScroll = styled.div.attrs({
})`

    overflow: auto; 

    ::-webkit-scrollbar-thumb {
        background-color: ${props => props.theme.palette.secondary.main};
        outline: 0 solid ${props => props.theme.palette.secondary.main};
    }

    ::-webkit-scrollbar {
        width: 1px;
        height: .2rem;
    }

    ::-webkit-scrollbar-track {  
    }
`;

export const SafeImage = styled.img.attrs({
    onError: e => e.target.style.display = 'none',
    onLoad: e => e.target.style.display = 'initial'
})``


export const Icon = styled(SafeImage).attrs((props) => ({
    src: `/icons/${props.icon}.svg`,
}))` 
    margin: ${props => props.nomargin ? `0` : `0 3px 0 -6px`} ;
    z-index: 1;
    ${props => props.pointer ? `cursor: pointer;` : ``}
    width: 22px;
    ${props => props.width ? `width: ${props.width}px;` : ``}
    cursor: ${props => props.pointer ? `pointer` : `default`};

`;

export const Title = styled.div.attrs({
})`           
    font-family: Poppins;
    font-size: ${props => props.small ? `20px` : `24px`};
    font-weight: 700;
    text-align: ${props => props.center ? `center` : `left`};
    color: ${props => props.theme.palette.primary.main};

    ${props => props.hasIcon ? `
        display: flex;
        align-items: flex-start;
        gap: 8px;
        flex-wrap: wrap;
        ` : ``}
`;

export const Text = styled.div.attrs({
})`           
    font-family: Poppins;
    font-size: 16px;
    text-align: ${props => props.center ? `center` : `left`};
    color: ${props => props.theme.palette.colors.black};
 
`;

export const ButtonContainer = styled.div.attrs({
})`
    display: flex;
    justify-content: ${props => props.center ? `center` : props.start ? `flex-start` : props.end ? `flex-end` : `space-between`};
    width: 100%;
    ${props => props.space ? `gap: 24px` : ``}
`;
