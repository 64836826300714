import React, { useContext, useMemo } from 'react'
import { HomeBodyContent, InfoStudyingContent, InfoStudyingText } from './styled'
import { Icon } from 'ui/styled'
import { CoreContext } from 'context/CoreContext'
import useI18n from 'hooks/useI18n'

export default function InfoStudying() {

  const { user } = useContext(CoreContext)

  const { t, language, translation } = useI18n(false)

  const options = useMemo(() => {
    return ([
      { title: `${ t("dashboard_home_learning") }:`, label: user?.idioma },
      { title: `${ t("dashboard_home_level") }:`, label: user?.level },
    ])
  }, [user, translation]);

  return !options?.length ? null : (
    <>
      <HomeBodyContent>

        {
          options?.map((option, index) => (
            <InfoStudyingContent key={index}>
              <InfoStudyingText>{option.title} <InfoStudyingText medium>{option.label}</InfoStudyingText></InfoStudyingText>
              {/* <Icon icon='chevron-down' /> */}
            </InfoStudyingContent>
          ))
        }
      </HomeBodyContent>
    </>
  )
};
