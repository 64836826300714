import React from 'react'  

import { PasswordValidationContainer } from './styled'
import Check from '../Check'
import useI18n from 'hooks/useI18n'


export default function PasswordValidation({ password, setValidPassword }) {
  const validations = {
      hasUpperCase: password?.match(/[A-Z]/),
      hasNumber: password?.match(/[0-9]/),
      hasMinLength: password?.length > 5,
      hasSpecialChar: password?.match(/[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/),
  };

  setValidPassword(Object.values(validations).every(Boolean));

  const { t } = useI18n(false)

  return (
      <>   
          <PasswordValidationContainer>
              <Check checked={validations.hasUpperCase} label={t("dashboard_create_new_password_check1_text")} />
              <Check checked={validations.hasNumber} label={t("dashboard_create_new_password_check2_text")} />
              <Check checked={validations.hasMinLength} label={t("dashboard_create_new_password_check3_text")} />
              <Check checked={validations.hasSpecialChar} label={t("dashboard_create_new_password_check4_text")} />
          </PasswordValidationContainer>  
      </>
  );
}