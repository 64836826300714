import React, { useContext, useEffect, useMemo, useState } from 'react'
import { SayingButtonContainer, SayingErrorContainer, SayingErrorContent, SayingErrorImg, SayingErrorTableContainer, SayingErrorText } from './styled'
import { Title } from 'ui/styled'
import RightWrongTable from 'components/RightWrongTable'
import Button from 'components/Form/Button'
import HighlightedText from 'components/HighlightedText'
import { theme } from 'ui/theme-color'
import { CoreContext } from 'context/CoreContext'
import useI18n from 'hooks/useI18n'
import { useHistory } from 'react-router-dom';

export default function SayingError({ toCorrect, action, error }) {
  const { reinforcement, handleStateReset } = useContext(CoreContext)
  const [message, setMessage] = useState("")
  const history = useHistory();
  const navigate = to => history.push(`/${to}`);

  const { t } = useI18n(false)

  const wrong = useMemo(() => {
    return JSON.parse(toCorrect?.erradas || `[]`)
  }, [toCorrect])

  const correct = useMemo(() => {
    return JSON.parse(toCorrect?.correcoes || `{}`)
  }, [toCorrect])

  const omitted = useMemo(() => {
    return JSON.parse(toCorrect?.palavras_omitidas || `[]`)
  }, [toCorrect])

  useEffect(() => {
    if (wrong?.length > 1 && omitted?.length === 1) {
      setMessage(`${wrong?.length} ${t("dashboard_dict_wrong_words")} / ${omitted?.length} ${t("dashboard_dict_wrong_omit_singular")}`)
    } else if (wrong?.length === 1 && omitted?.length > 1) {
      setMessage(`${wrong?.length} ${t("dashboard_dict_wrong_words_singular")} / ${omitted?.length} ${t("dashboard_dict_wrong_omit")}`)
    } else if (wrong?.length === 1 && omitted?.length === 1) {
      setMessage(`${wrong?.length} ${t("dashboard_dict_wrong_words_singular")} / ${omitted?.length} ${t("dashboard_dict_wrong_omit_singular")}`)
    } else {
      setMessage(`${wrong?.length} ${t("dashboard_dict_wrong_words")} / ${omitted?.length} ${t("dashboard_dict_wrong_omit")}`)
    }
  }, [wrong, omitted])

  const handleClose = () => {
    handleStateReset()
    navigate('dashboard')
  }

  return (
    <>
      <SayingErrorContainer>
        <SayingErrorImg />
        <SayingErrorContent>
          <Title small>
            { message }
          </Title>
          <SayingErrorText>
            <HighlightedText error={ wrong?.length ? { ...error, text: toCorrect?.texto_correto || "", words: wrong, backgroundColor: theme.palette.wrong.main } : null }  />
          </SayingErrorText>

          <SayingErrorTableContainer reinforcement={reinforcement}>
            { ! wrong?.length ? null : <RightWrongTable item={{ correct, wrong }} /> }
            { ! omitted?.length ? null : <RightWrongTable items={omitted} /> }
          </SayingErrorTableContainer>
          <SayingButtonContainer>
            <Button width='240px' secondary outline nospace colorBlack onClick={handleClose}>
              { t("dashboard_dict_wrong_finish") }
            </Button>
            {
              !action ? null : 
              <Button width='240px' secondary nospace onClick={action}>
                { reinforcement ? t("dashboard_dict_wrong_challange") : t("dashboard_dict_wrong_reinforcement") }
              </Button>
            }
          </SayingButtonContainer>

        </SayingErrorContent>
      </SayingErrorContainer>
    </>
  )
}
